import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function resolutionDetailsValidator(condition: (value: string) => boolean): ValidatorFn {
  return (group: FormGroup): ValidationErrors => {
    const resolutionDetails = group.get('resolutionDetails');
    const resolution = group.get('resolution');

    if (condition(resolution.value) && !resolutionDetails.value) {
      resolutionDetails.setErrors({ required: true });
    }

    return;
  };
}
