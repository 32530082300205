import { OrganizationDto } from '@core/services/dto';

export class OrganizationModel {
  id: number;
  name: string;
  sin: string;
  type: string;

  constructor(dto: OrganizationDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.sin = dto.sin;
    this.type = dto.type;
  }
}
