import { Injectable } from '@angular/core';
import { ApiClient } from '@core/services/api-client.http.service';
import {
  PaginatedResponseDto,
  UserGroupDetailsDto,
  UserManagementUsersDto,
} from '@core/services/dto';
import {
  OfferUrls,
  UserGroupManagementUrls,
  UserManagementUrls,
} from '@core/constants';
import { Observable } from 'rxjs';
import { UserManagementModel } from '@shared/models';
import { UserOfferUpdateApiRequest } from '@shared/models/requests/user-offer-update.request.dto';

@Injectable({
  providedIn: 'any',
})
export class UserManagementApiService {
  constructor(private api: ApiClient) {}

  getData(request): Observable<PaginatedResponseDto<UserManagementUsersDto>> {
    return this.api.get<PaginatedResponseDto<UserManagementUsersDto>>(
      UserManagementUrls.users,
      request
    );
  }

  getOfferData(
    request
  ): Observable<PaginatedResponseDto<UserManagementUsersDto>> {
    return this.api.get<PaginatedResponseDto<UserManagementUsersDto>>(
      OfferUrls.offerUsers,
      request
    );
  }

  saveUserPermission(id, permissions): Observable<UserManagementUsersDto> {
    return this.api.put<UserManagementUsersDto>(
      UserManagementUrls.permissions(id),
      permissions
    );
  }

  getUserId(id): Observable<UserManagementUsersDto> {
    return this.api.get<UserManagementUsersDto>(UserManagementUrls.user(id));
  }

  updateUserId(
    id,
    request: UserManagementModel
  ): Observable<UserManagementUsersDto> {
    return this.api.put<UserManagementUsersDto>(
      UserManagementUrls.user(id),
      request
    );
  }

  getPermissions(id): Observable<PaginatedResponseDto<number>> {
    return this.api.get<PaginatedResponseDto<number>>(
      UserManagementUrls.permissions(id)
    );
  }

  getUserGroups(id): Observable<PaginatedResponseDto<UserGroupDetailsDto>> {
    return this.api.get<PaginatedResponseDto<UserGroupDetailsDto>>(
      UserManagementUrls.groups(id)
    );
  }

  saveUserGroups(id, ids): Observable<PaginatedResponseDto<number>> {
    return this.api.put<PaginatedResponseDto<number>>(
      UserManagementUrls.groups(id),
      ids
    );
  }

  getGroups(request): Observable<PaginatedResponseDto<UserGroupDetailsDto>> {
    return this.api.get<PaginatedResponseDto<UserGroupDetailsDto>>(
      UserGroupManagementUrls.groups,
      request
    );
  }

  createFolder(request): any {
    return this.api.get<any>(UserGroupManagementUrls.groups, request);
  }

  getOfferUserData(
    request
  ): Observable<PaginatedResponseDto<UserManagementUsersDto>> {
    return this.api.get<PaginatedResponseDto<UserManagementUsersDto>>(
      UserManagementUrls.users,
      request
    );
  }

  sendActivation(request): Observable<UserManagementUsersDto> {
    return this.api.put<UserManagementUsersDto>(OfferUrls.OfferUser, request);
  }

  enableDisableOfferUser(request): Observable<any> {
    return this.api.put<any>(OfferUrls.OfferUserEnable, request);
  }
}
