import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableColumnModel } from '@shared/components/custom-table/models/TableColumn.model';

@Component({
  selector: 'app-accordion-row-builder',
  templateUrl: './accordion-builder.component.html',
  styleUrls: ['./accordion-builder.component.scss']
})
export class AccordionBuilderComponent {
  @Input() data: any[];
  @Input() tableConfig: TableColumnModel[];
  @Output() clickAction: EventEmitter<any> = new EventEmitter<any>();

  onIconHandler(state: boolean, item): void {
    item.visible = state;
  }

  emitClick($event): void {
    this.clickAction.emit($event);
  }
}
