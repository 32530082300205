import { Toolbar } from 'ngx-editor';

export const ToolbarConfig: Toolbar = [
  ['bold', 'italic'],
  ['underline', 'strike'],
  ['code', 'blockquote'],
  ['ordered_list', 'bullet_list'],
  [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
  ['link', 'image'],
  ['text_color', 'background_color'],
  ['align_left', 'align_center', 'align_right', 'align_justify']
];

export const OfferToolbarConfig: Toolbar = [
  ['bold', 'italic'],
  ['underline'],
  ['ordered_list', 'bullet_list'],
  ['link'],
];
