import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableColumnModel } from '@shared/components/custom-table/models/TableColumn.model';
import { reportColumnConfig } from 'app/modules/reports/sale-reporting/columns.config';

@Component({
  selector: 'app-accordion-row-item',
  templateUrl: './accordion-row-item.component.html',
  styleUrls: ['./accordion-row-item.component.scss']
})
export class AccordionRowItemComponent {
  @Input() data: any[];
  @Input() hasChildren: boolean;
  @Input() tableConfig: TableColumnModel[];
  @Output() clickAction: EventEmitter<any> = new EventEmitter<any>();
  @Input() head: any;
  @Output() iconAction = new EventEmitter<boolean>();
  tableColumns: TableColumnModel[] = reportColumnConfig;
  visible: boolean;

  emitClick($event): void {
    this.clickAction.emit($event);
  }

  public arrowIconHandler(state: boolean): void {
    this.iconAction.emit(state);
  }
}
