export enum ConversationImportStatusEnum {
  Ready = 1,
  InProgress,
  Done,
  Pending,
  Processed
}

export const ConversationImportStatusOptions = [
  { value: ConversationImportStatusEnum.Ready, label: 'Ready' },
  { value: ConversationImportStatusEnum.InProgress, label: 'InProgress' },
  { value: ConversationImportStatusEnum.Done, label: 'Done' },
  { value: ConversationImportStatusEnum.Pending, label: 'Pending' },
  { value: ConversationImportStatusEnum.Processed, label: 'Processed' }
];
