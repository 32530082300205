import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ConversationModel } from '@shared/models/conversation.model';
import { CreateConversationStateService } from '../create-conversation.state.service';
import { CustomSnackbarService } from '@core/services/custom-snackbar.service';
import { GlobalStateService } from '@core/services/global.state.service';
import { ConversationType } from '@shared/configs/conversation-types.config';
import { CreateConversationCommonComponent } from '@shared/components/conversation/create-conversation-common.component';
import { Observable, Subject } from 'rxjs';
import { ConversationCreateModel, SearchMemberOptionsModel } from '@shared/models';
import { FileTypeEnum } from '@shared/enums';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';
import { AssigneeFormControl } from '@shared/controls/assignee-form-control';
import { ConversationApiService, TaskApiService, UserGroupManagementApiService } from '@core/services/api.services';
import { EnailNotificationApiService } from '@core/services/api.services/email-notification-api.service';
import { UserManagementStateService } from 'app/modules/setup/user-management/user-management.state.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GroupTypeStringEnum } from '@shared/enums/group-type.enum';

@Component({
  selector: 'app-create-conversation',
  templateUrl: './create-conversation.component.html',
  styleUrls: ['./create-conversation.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class CreateConversationComponent extends CreateConversationCommonComponent implements OnInit, AfterViewInit {
  FileTypeEnum = FileTypeEnum;
  GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  defaultAssigneeUserName = new Subject<SearchMemberOptionsModel>();
  conversationUsers$ = this.state.conversationUsers$();
  selectedUser: any;
  isRepRole = false;

  @Output() formValueChanged = new EventEmitter<boolean>();

  constructor(
    fb: FormBuilder,
    state: CreateConversationStateService,
    toast: CustomSnackbarService,
    globalState: GlobalStateService,
    taskService: TaskApiService,
    conversationService: ConversationApiService,
    htmlElementRef: ElementRef,
    service: EnailNotificationApiService,
    private userstate: UserManagementStateService,
    private groupState: UserGroupManagementApiService
  ) {
    super(fb, state, globalState, toast, taskService, conversationService, htmlElementRef, service);
    this.getGroupByUserId(this.globalState.user.id);
    this.selectedUser = {
      label: this.globalState.user.firstName + ' ' + this.globalState.user.lastName,
      ref: this.globalState.user.id,
      type: null,
      value: null
    };
    this.form.get('conversationType').patchValue(ConversationType.conversation);
    this.form.addControl('assigneeId', new FormControl(''));

  }

  initialState: any = {};
  ngOnInit(): void {
    super.ngOnInit();

    this.form.get('assigneeId').valueChanges.subscribe(name => {
      if (!name) {
        this.isRepRole = false;
        return;
      }

      if (name?.length >= GLOBAL_SETTINGS.minLengthToTriggerSearch) {
        this.state.getSearchedUserGroups(name, true);
      }
    });
  }

  getGroupByUserId(id: any): void {
    const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (pattern.test(id) === false) {
      this.groupState.getGroup(id).subscribe((response) => {
        const res = response;
        if (res.type === GroupTypeStringEnum.All ||
          response.type === GroupTypeStringEnum.CRMandRepresentativeRole ||
          response.type === GroupTypeStringEnum.ECMandRepresentativeRole) {
          this.isRepRole = true;
        }
      });
    }
  }

  onOptionSelected(event: any): void {
    if (event && event.hasOwnProperty('label')) {
      this.getGroupByUserId(event.ref);
    }
  }

  ngAfterViewInit(): void {
    this.checkFormChanges();
  }

  saveConversationType(): Observable<ConversationModel> {
    if (this.formLinks?.length) {
      this.formLinks.forEach(element => {
        if (element.isProspectInfo === true) {
          element.type = 'Prospect';
        } else if (element.isProspectInfo === false) {
          element.type = 'Member';
        }
        else {
          // eslint-disable-next-line
          element.type = element.type;
        }
      });
    }
    const conversation: ConversationCreateModel = {
      subject: this.form.value.subject,
      description: this.form.value.description,
      status: this.form.value.status,
      productId: this.form.value.productId,
      attachments: this.form.value.attachments.map(x => x.id),
      links: this.formLinks,
      assigneeId: this.form.value.assigneeId.ref
      // assigneeId: this.selectedUser ? this.selectedUser.ref : '',

    };
    return this.state.setConversation(conversation);
  }

  getOptionText(option): any {
    return option?.label;
  }

  onAddingInteraction($event): void {
    this.addingInteraction = $event;
    this.formValueChanged.emit(true);
  }

  onAddingTask($event): void {
    this.addingTask = $event;
    this.formValueChanged.emit(true);
  }

  onAddingSale($event): void {
    this.addingSale = $event;
    this.formValueChanged.emit(true);
  }


}
