import { CampaignsDto } from '@core/services/dto/campaigns.dto';

export class CampaignsModel {
  label: string;
  value: number;

  constructor(dto: CampaignsDto) {
    this.value = dto.id;
    this.label = dto.name;
  }
}
