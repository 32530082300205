import { NoteDto } from '@core/services/dto/note.dto';

export class SearchNoteOptionsModel {
  label?: string;
  ref: string;
  type: string;
  value?: string;

  constructor(dto: NoteDto) {
    this.label = dto.description;
    this.value = dto.description;
    this.ref = dto.noteId?.toString();
    this.type = '';
  }
}
