import { HoldDto } from '@core/services/dto';
import { formatDateSimple } from '@shared/utils';

export class HoldModel {
  id: number;
  type: string;
  effectiveDate: string;
  releaseDate: string;
  amount: number;
  status: string;
  currency: string;

  constructor(dto: HoldDto) {
    this.id = dto.id;
    this.type = dto.type;
    this.effectiveDate = dto.effectiveDate ? formatDateSimple(dto.effectiveDate) : '';
    this.releaseDate = dto.releaseDate ? formatDateSimple(dto.releaseDate) : '';
    this.amount = dto.amount;
    this.status = dto.status;
    this.currency = dto.currency;
  }
}
