import { ConversationDto } from '@core/services/dto';
import { LinkModel } from '@shared/models/link.model';

export class SearchConversationOptionsModel {
  label: string;
  ref: string;
  type: string;
  value: string;
  links?: LinkModel[];


  constructor(dto: ConversationDto | any) {
    this.label = dto.subject;
    this.value = dto.subject;
    this.ref = dto.id.toString();
    this.type = '';
    this.links = dto.links;
  }
}
