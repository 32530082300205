import { UserManagementUsersDto } from '@core/services/dto';
import { OfferUserStatusEnum } from '@shared/enums/offer-user-status.enum';
import { formateUTCtoTZ } from '@shared/utils';

export class UserManagementModel {
  branch: string;
  email: string;
  firstName: string;
  id: string;
  lastLogin: string;
  lastName: string;
  status: string;
  name: string;
  roles: string[];
  type: 'Team' | 'Full';
  initials: string;
  fullName: string;
  isHidden?: boolean;

  constructor(dto: UserManagementUsersDto) {
    this.branch = dto.branch?.name;
    this.email = dto.email;
    this.firstName = dto.firstName;
    this.id = dto.id;
    this.lastLogin = dto.lastLogin
      ? formateUTCtoTZ(dto.lastLogin)
      : dto.lastLogin;
    this.lastName = dto.lastName;
    this.status = dto.status;
    this.name = `${dto.firstName} ${dto.lastName}`;
    this.roles = dto.roles;
    this.type = dto.type;
    this.initials = `${dto.firstName.charAt(0)}${dto.lastName.charAt(0)}`;
    this.fullName = `${dto.firstName} ${dto.lastName}`;
  }

  isReadyForActivation(): boolean {
    return OfferUserStatusEnum[this.status] === OfferUserStatusEnum.Inactive;
  }

  isActivationInProcess(): boolean {
    return OfferUserStatusEnum[this.status] === OfferUserStatusEnum.InProgress;
  }
}


