import { Pipe, PipeTransform } from '@angular/core';
import { formatDateSimple } from '@shared/utils';
import { of } from 'rxjs';

@Pipe({ name: 'customDate' })
export class CustomDatePipe implements PipeTransform {
  transform(value: any): any {
    const formattedValue = !!value ? formatDateSimple(value) : '';

    return of(formattedValue);
  }
}
