import { of } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'emptyFieldPlaceholder' })
export class EmptyFieldPlaceholderPipe implements PipeTransform {
  transform(value: any): any {
    const formattedValue = (value === null || value === undefined || value === '') ? 'N/A' : value;
    return of(formattedValue);
  }
}
