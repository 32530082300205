import { ImportConversationDto } from '@core/services/dto';
import { ConversationImportStatusEnum } from '@shared/enums/conversation-import-status.enum';
import { formateUTCtoTZ } from '@shared/utils';

export class ImportConversationModel {
  file: {
    id: number;
    name: string;
  };
  id: number;
  status: string;
  imported: string;
  importedBy: string;
  fileName: string;
  assigneeId?: string;
  importType?: string;
  conversationImportStatus?: string;
  executionSummaryFile?: any;

  constructor(dto: ImportConversationDto) {
    this.file = dto.file;
    this.id = dto.id;
    this.imported = dto.imported ? formateUTCtoTZ(dto.imported) : dto.imported;
    this.importedBy = dto.importedBy ? `${dto.importedBy?.firstName} ${dto.importedBy?.lastName}` : '';
    this.fileName = dto.file?.name;
    this.status = dto.status;
    this.assigneeId = dto.assigneeId;
    this.importType = dto?.importType;
    this.conversationImportStatus = dto?.conversationImportStatus;
    this.executionSummaryFile = dto?.executionSummaryFile;
  }

  isReadyForImport(): boolean {
    return ConversationImportStatusEnum[this.conversationImportStatus] === ConversationImportStatusEnum.Ready;
  }

  isImportInProcess(): boolean {
    return ConversationImportStatusEnum[this.status] === ConversationImportStatusEnum.InProgress;
  }
}
