export enum IndustryEnum {
    AccommodationFoodServices = 1,
    AccountantAuditor = 2,
    AdministrationClerical = 3,
    AgriculturalFarmer = 4,
    ManufacturingAssemblyLineWorker = 5,
    PublicAdministrationFedOrProvGovtServices = 6,
    InsuranceServicesProfessionalAssistant = 7,
    AutomotiveSalespersonDealer = 8,
    BusinessProfessionalManager = 9,
    CanadianMilitaryCanadianMilitaryOfficer = 10,
    DiplomaticOfficialAmbassadorCounselorToAmbassador = 11,
    ArtsCultureRecreationAndSports = 12,
    HealthCareDentalHygienist = 13,
    HealthCareDentist = 14,
    HealthCarePhysicianSpecializedPhysician = 15,
    DriverTransportTransitDeliveryCourierService = 16,
    EngineerArchitect = 17,
    EstheticianHairstylistBarber = 18,
    FinanceAdministrativeServices = 19,
    FinanceBankingServicesProfessional = 20,
    Homemaker = 21,
    FinanceInvestmentPlanner = 22,
    ITComputerRelatedJobs = 23,
    LawEnforcementJudge = 24,
    LabourerOutsideWorker = 25,
    LawEnforcementPoliceSecurityGuardCorrectionalServices = 26,
    LawEnforcementLawyerNotary = 27,
    HeavyEquipmentOperatorMetalToolOperator = 28,
    HealthCareNurseRPN = 29,
    OwnerOfABusiness = 30,
    HealthCarePhysiotherapistChiropractor = 31,
    ManufacturingProcessingMachineOperator = 32,
    RealEstateAgentBroker = 33,
    ReligiousVocation = 34,
    RetailSalespersonClerkManager = 35,
    Retired = 36,
    EducationSchoolTeacher = 37,
    FinanceSecurityBrokerDealer = 38,
    ServiceOperators = 39,
    SkilledTradesperson = 40,
    SpecializedSkillTraining = 41,
    StudentChild = 42,
    Technician = 43,
    Unemployed = 44,
    EducationUniversityCollegeProfessor = 45,
    ContructionCertifiedTradesperson = 46,
    ConstructionTradespersonLabourer = 47,
    ScienceBiologistChemistArcheologist = 48,
    SocialMentalHealthSocialServicesWorkers = 49,
    TravelTourismAirlineBusStaff = 50,
    TravelTourismTravelAgentTourGuide = 51,
    HealthCareLabTechXRayTechAssistant = 52,
    Forestry = 53,
    Mining = 54,
    MediaBroadcasting = 55
}
