import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserManagementUrls, UserUrls } from '@core/constants/endpoints.constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { UserApiRequest } from '@shared/models/requests/user.request.dto';
import { PaginatedResponseDto, UserDto, UserManagementUsersDto } from '@core/services/dto';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(private api: ApiClient) {
  }

  getUsers(request: UserApiRequest): Observable<PaginatedResponseDto<UserDto>> {
    return this.api.get<PaginatedResponseDto<UserDto>>(UserUrls.users, request);
  }

  getUserGroups(request: UserApiRequest): Observable<PaginatedResponseDto<UserDto>> {
    return this.api.get<PaginatedResponseDto<UserDto>>(UserUrls.userGroups, request);
  }

  getUsersUserManagements(request: UserApiRequest): Observable<PaginatedResponseDto<UserManagementUsersDto>> {
    return this.api.get<PaginatedResponseDto<UserManagementUsersDto>>(UserManagementUrls.users, request);
  }

}
