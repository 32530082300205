<div class="sm-font-container">
  <form [formGroup]="form">
    <div fxLayout>
      <div fxFlex="65" class="left-col">
        <mat-form-field appearance="outline" [style.width.%]="100">
          <mat-label>Conversation Type</mat-label>
          <mat-select formControlName="conversationType">
            <ng-container *ngFor="let option of typeOptions">
              <mat-option [value]="option.value">{{option.label}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="">
        <mat-form-field appearance="outline">
          <mat-label>Conversation Status</mat-label>
          <mat-select formControlName="status">
            <ng-container *ngFor="let option of statusOptions">
              <mat-option [value]="option.value">{{option.label}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout>
      <div fxFlex="50" class="left-col">
        <mat-form-field appearance="outline" [style.width.%]="100">
          <mat-label>Product Type</mat-label>
          <mat-select formControlName="productType">
            <ng-container *ngFor="let option of productTypeOptions">
              <mat-option [value]="option.value">{{option.label}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <mat-form-field appearance="outline" [style.width.%]="100">
          <mat-label>Product</mat-label>
          <mat-select formControlName="productId">
            <ng-container *ngFor="let option of productOptions">
              <mat-option [value]="option.value">{{option.label}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div>
      <mat-form-field [style.width.%]="100" appearance="outline">
        <mat-label>Concern Type</mat-label>
        <mat-select formControlName="concernType">
          <ng-container *ngFor="let option of concernTypeOptions">
            <mat-option [value]="option.value">{{option.label}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout>
      <div fxFlex="50" class="left-col">
        <div>
          <div fxFlex>
            <mat-form-field appearance="outline" class="assignedTo-container">
              <mat-label>Assigned To</mat-label>
              <input type="text" [(ngModel)]="selectedUser" formControlName="assigneeId" matInput
                placeholder="Assigned To" [matAutocomplete]="auto" (ngModelChange)="onOptionSelected($event)"
                matAutocompletePosition="below" required="true">
              <mat-icon matSuffix><i class="icon-search"></i></mat-icon>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
                <mat-option *ngFor="let user of conversationUsers$ | async" [value]="user"
                  [disabled]="user.status === 'Inactive'"
                  [ngStyle]="{'color': user.status === 'Inactive' ? 'gray' : 'initial'}">
                  {{ user.label}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxFlex="50" *ngIf="isRepRole" style="padding-top: 3%">
        <img style="height: 22px; width: 22px; padding-left: 3%;" src="assets/icons/rep_role-icon.svg"
          matTooltip="Representative Role" matTooltipPosition="above" matTooltipClass="custom-tooltip" />
      </div>

      <div fxFlex="50">
        <div>
          <mat-form-field [style.width.%]="100" appearance="outline">
            <mat-label>Resolution</mat-label>
            <mat-select formControlName="resolution">
              <ng-container *ngFor="let option of resolutionOptions">
                <mat-option [value]="option.value">{{option.label}}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div>
      <mat-form-field [style.width.%]="100" appearance="outline">
        <mat-label>Subject</mat-label>
        <input matInput placeholder="Subject" formControlName="subject" min="3">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" [style.width.%]="100">
        <mat-label>Description</mat-label>
        <textarea matInput placeholder="Description Text Here" formControlName="description"></textarea>
      </mat-form-field>
    </div>
    <div *ngIf="concernResolved(form.get('resolution').value)">
      <mat-form-field fxFlex="100" appearance="outline">
        <mat-label>Resolution Details</mat-label>
        <textarea matInput placeholder="Resolution Description Text Here"
          formControlName="resolutionDetails"></textarea>
      </mat-form-field>
    </div>
  </form>
  <div class="actions">
    <app-add-interaction-to-conversation [conversationSubject]="form.get('subject').value"
      (addingInteraction)="addingInteraction = $event" (interactionChange)="updateInteraction($event)"
      [ngClass]="addingInteraction ? 'active' : ''">
    </app-add-interaction-to-conversation>
    <app-add-task-to-conversation (addingTask)="addingTask = $event" (taskChange)="updateTask($event)"
      [ngClass]="addingTask ? 'active' : ''">
    </app-add-task-to-conversation>
  </div>

  <div>
    <form [formGroup]="formWorkflow">
      <mat-form-field appearance="outline" [style.width.%]="100">
        <mat-label>Add Workflow</mat-label>
        <div class="workflow-icon" matPrefix><i class="icon-workflow"></i></div>
        <mat-select formControlName="workflowTemplateId">
          <ng-container *ngFor="let option of workflowOptions">
            <mat-option [value]="option.value">{{option.label}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <app-add-link-to-conversation (linkAdded)="addLinks($event)"></app-add-link-to-conversation>

  <mat-divider></mat-divider>
  <app-upload-file [parentForm]="form" [fileType]="FileTypeEnum.Crm" [readOnly]="false"></app-upload-file>

  <div>
    <cm-button color="primary" class="record-btn" (click)="save()"
      [disabled]="!form.valid || !formLinks.length || !interactionValid || !taskValid">
      Record
    </cm-button>
  </div>
</div>