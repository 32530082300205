<div class="sticky-background" [ngClass.lt-lg]="'tablet'">
  <div fxLayout [ngClass.lt-lg]="'tablet'" class="sticky-wrap">
    <div fxLayout class="header-center-col shadow" fxFlex>
      <div fxFlex="60px">
        <i class="icon-search" *ngIf="isOfferOnly"></i>
      </div>
      <div fxFlex>
        <form [formGroup]="form"
              *appCheckPermission="permissionMembers">
          <app-input-autocomplete formControlName="members"
                                  class="search-field"
                                  placeholder="Enter Member/Prospect Number or Name"
                                  [lengthToTriggerSearch]="GLOBAL_SETTINGS.minLengthToTriggerSearch"
                                  [options]="members$ | async"
                                  (selectedItem)="memberClicked($event)"
                                  [icon]="'none'">
          </app-input-autocomplete>
        </form>      
      </div>
      <div fxFlex>
        <div class="header-menu-wrap">
          <div class="separator"></div>
          <ng-container *ngIf="isOfferOnly">
            <div
              *appCheckPermission="permissionConcerns"
              class="header-menu"
              (click)="openConcerns()"
            >
              <i class="icon-dialog"></i>
              <span class="tasks-circle">{{ activeConversationsCount }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="isOfferOnly">
            <div
              *appCheckPermission="permissionViewTasks"
              class="header-menu"
              (click)="openTasks()"
            >
              <i class="icon-tasks"></i>
              <span class="tasks-circle">{{ activeTasksCounts }}</span>
            </div>
          </ng-container>
          <div class="profile">
            <div>{{ getInitials() }}</div>
          </div>
          <div class="greeting">Hi {{ userName.firstName }}</div>
          <app-three-dots-menu color="secondary">
            <ng-container menu>
              <button
                class="mat-menu-item"
                mat-menu-item
                fxLayout
                fxLayoutGap="10px"
                (click)="logout()"
              >
                <i class="icon-logout"></i>
                <span>Logout</span>
              </button>
            </ng-container>
          </app-three-dots-menu>
        </div>
      </div>
    </div>
    <div class="action-list"
         fxLayout="row">
      <div class="item">
        <app-header-action-item [actionText]="'Add Prospect'"
                                [actionType]="'create_prospect'"
                                [hasPermission]="permissionManageProspects">
        </app-header-action-item>
      </div>
      <div class="item"
           *appCheckPermission="permissionAddTasks">
        <app-header-action-item [actionText]="'Add task'"
                                [actionType]="'task'">
        </app-header-action-item>
      </div>
      <div class="item"
           *appCheckPermission="permissionConversation">
        <app-header-action-item [actionText]="'Add conversation'"
                                [actionType]="'create_conversation'">
        </app-header-action-item>
      </div>
      <div class="item"
           *appCheckPermission="permissionNotes">
        <app-header-action-item [actionText]="'Add note'"
                                [actionType]="'create_note'">
        </app-header-action-item>
      </div>
      <div class="item"
           *appCheckPermission="permissionSales">
        <app-header-action-item [actionText]="'Add sale'"
                                [actionType]="'sale'">
        </app-header-action-item>
      </div>
    </div>
  </div>
</div>
