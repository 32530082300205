import { Injectable } from '@angular/core';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchUserOptionsModel } from '@shared/models';
import { map } from 'rxjs/operators';
import { PaginatedResponseDto, UserDto } from '@core/services/dto';
import { UserApiRequest } from '@shared/models/requests/user.request.dto';
import { UserApiService } from '@core/services/api.services/user-api.service';

@Injectable({ providedIn: 'any' })

export class ReferrerCardStateService extends autoUnsubscribeMixin() {
  public referredByUsers: BehaviorSubject<SearchUserOptionsModel[]> = new BehaviorSubject<SearchUserOptionsModel[]>([]);

  referredByUsers$(): Observable<SearchUserOptionsModel[]> {
    return this.referredByUsers.asObservable();
  }

  constructor(
    private userService: UserApiService
  ) {
    super();
  }

  getSearchedUsers(search: string, userIdsToExclude: string[]): void {
    this.getUsers(search, userIdsToExclude).pipe(

      map((response) => {

        return {
          ...response,
          data: response.data.map(item => new SearchUserOptionsModel(item))
        };
      })
    ).subscribe(response => {
      this.referredByUsers.next(response.data);
    });

  }

  getUsers(searchString: string, userIdsToExclude: string[]): Observable<PaginatedResponseDto<UserDto>> {
    const request: UserApiRequest = {
      pageSize: 3,
      search: searchString,
      excludedUserIds: userIdsToExclude
    };
    return this.userService.getUsers(request);
  }
}
