import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OfferUrls } from '@core/constants/endpoints.constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { OfferTemplateDto } from '@core/services/dto/offer-template.dto';
import {
  EmailTemplateDto,
  OfferActivityDto,
  OfferDto,
  OfferTemplateIconsDto,
  PaginatedResponseDto,
} from '@core/services/dto';
import { OfferTemplateRequest } from '@shared/models/requests/offer-template.request.dto';
import { OfferTemplate } from '@shared/models';
import { ImportOfferApiRequest } from '@shared/models/requests/Import-offer.request.dto';
import { ImportOfferDto } from '../dto/import-offers.dto';
import {
  OfferActivityApiRequest,
  OfferApiRequest,
} from '@shared/models/requests/offer.request.dto';
import { OfferStatusUpdateApiRequest } from '@shared/models/requests/offer-status-update.request.dto';
import { MemberOfferRequest } from '@shared/models/requests/member-offer.request.dto';
import { MemberOfferDto } from '../dto/member-offer.dto';
import { OfferSiteDetailDto } from '../dto/offer-site-detail.dto';
import { getOfferApiBase } from '@config/org.config';

const offerBase = getOfferApiBase();

@Injectable({
  providedIn: 'root',
})
export class OfferApiService {
  constructor(private api: ApiClient) {}

  // Offer Template

  getOfferTemplates(
    request: OfferTemplateRequest
  ): Observable<PaginatedResponseDto<OfferTemplateDto>> {
    return this.api.get<PaginatedResponseDto<OfferTemplateDto>>(
      OfferUrls.OfferTemplates,
      request
    );
  }

  getOfferTemplate(id: number): Observable<OfferTemplateDto> {
    return this.api.get<OfferTemplateDto>(OfferUrls.offerTemplate(id));
  }

  getOfferTemplateIcons(): Observable<OfferTemplateIconsDto[]> {
    return this.api.get<OfferTemplateIconsDto[]>(
      OfferUrls.OfferTemplateIcons(1)
    );
  }

  createOfferTemplate(offerTemplate: OfferTemplate): Observable<OfferTemplate> {
    return this.api.post<OfferTemplate>(
      OfferUrls.OfferTemplates,
      offerTemplate
    );
  }

  updateOfferTemplate(
    id: number,
    offerTemplate: OfferTemplate
  ): Observable<OfferTemplate> {
    return this.api.put(OfferUrls.offerTemplate(id), offerTemplate);
  }

  deleteOfferTemplate(id: number): Observable<any> {
    return this.api.delete<any>(OfferUrls.offerTemplate(id));
  }

  // Offer Import

  getImportedFiles(
    request: ImportOfferApiRequest
  ): Observable<PaginatedResponseDto<ImportOfferDto>> {
    return this.api.get<PaginatedResponseDto<ImportOfferDto>>(
      OfferUrls.offerImports,
      request
    );
  }

  importOffer(id: number): Observable<any> {
    return this.api.put(OfferUrls.offerImport(id));
  }

  deleteImportOffer(id: number): Observable<any> {
    return this.api.delete(OfferUrls.offerImport(id));
  }

  downloadOfferImportTemplate(): Observable<any> {
    return this.api.getFile(OfferUrls.offerImportTemplate, null);
  }

  // Offer

  getOffers(
    request: OfferApiRequest
  ): Observable<PaginatedResponseDto<OfferDto>> {
    return this.api.get<PaginatedResponseDto<OfferDto>>(
      OfferUrls.offers,
      request
    );
  }

  getOfferActivity(
    request: OfferActivityApiRequest
  ): Observable<PaginatedResponseDto<OfferActivityDto>> {
    return this.api.get<PaginatedResponseDto<OfferActivityDto>>(
      OfferUrls.offerActivity,
      request
    );
  }

  getOfferDetails(id: number): Observable<OfferDto> {
    return this.api.get<OfferDto>(OfferUrls.offer(id));
  }

  updateOfferStatus(
    id: number,
    request: OfferStatusUpdateApiRequest
  ): Observable<any> {
    return this.api.put<any>(OfferUrls.offerMemberStatus(id), request);
  }

  // Member-Offer

  getMemberOffers(
    request: MemberOfferRequest
  ): Observable<PaginatedResponseDto<MemberOfferDto>> {
    return this.api.get<PaginatedResponseDto<MemberOfferDto>>(
      OfferUrls.MemberOffers,
      request
    );
  }

  // Site - offer

  getOfferSiteDetails(): Observable<OfferSiteDetailDto> {
    if (offerBase !== undefined) {
      return this.api.get<OfferSiteDetailDto>(OfferUrls.OfferSite);
    }
  }
}
