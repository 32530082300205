<div class="table__content"
     *ngFor="let col of data">
  <div class="internal__row"
       (click)="visible = !visible">
    <app-record-column-item (clickAction)="emitClick($event)"
                            (iconAction)="arrowIconHandler($event)"
                            [head]="head"
                            [tableConfig]="tableConfig"
                            [col]="col">
    </app-record-column-item>
  </div>
  <div *ngIf="visible">
    <div [ngClass]="visible ? 'collapse' : 'expand'"
         class="internal__row content"
         *ngFor="let col of col.children">
      <app-record-column-item [col]="col"
                              [tableConfig]="tableConfig">
      </app-record-column-item>
    </div>
  </div>
</div>



