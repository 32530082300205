import {RecentActivitySummaryDto} from '@core/services/dto/recent-activity-summary.dto';

export class RecentActivitySummaryModel {
  completedTasksCount: number;
  activeTasksCount: number;
  createdConversationsCount: number;
  createdConcernsCount: number;

  constructor(dto: RecentActivitySummaryDto) {
    this.completedTasksCount = dto.completedTasksCount;
    this.activeTasksCount = dto.activeTasksCount;
    this.createdConversationsCount = dto.createdConversationsCount;
    this.createdConcernsCount = dto.createdConcernsCount;
  }
}
