import { ContactTypeEnum } from '@shared/enums';
import { BusinessRoleTypeEnum } from '@shared/enums/business-role-type.enum';
import { BusinessTypeEnum } from '@shared/enums/business-type.enum';
import { LeadTypeEnum } from '@shared/enums/lead-type.enum';
import { BusinessPhoneTypeEnum, PhoneTypeEnum } from '@shared/enums/phone-type.enum';
import { ProspectStatusEnum } from '@shared/enums/prospect-status.enum';
import { SaluationEnum } from '@shared/enums/saluation.enum';
import { SelectOption } from '@shared/interfaces';

export const LeadTypeOptions: SelectOption<string>[] = [
  {
    value: LeadTypeEnum[LeadTypeEnum.WalkIn],
    label: 'Walk In'
  },
  {
    value: LeadTypeEnum[LeadTypeEnum.Online],
    label: 'Online'
  },
  {
    value: LeadTypeEnum[LeadTypeEnum.Phone],
    label: 'Phone'
  },
  {
    value: LeadTypeEnum[LeadTypeEnum.Other],
    label: 'Other'
  }
];

export const PhoneTypeOptions: SelectOption<string>[] = [
  {
    value: PhoneTypeEnum[PhoneTypeEnum.Home],
    label: 'Home'
  },
  {
    value: PhoneTypeEnum[PhoneTypeEnum.Mobile],
    label: 'Mobile'
  }
];

export const BusinessPhoneTypeOptions: SelectOption<string>[] = [
  {
    value: BusinessPhoneTypeEnum[BusinessPhoneTypeEnum.Home],
    label: 'Home'
  },
  {
    value: BusinessPhoneTypeEnum[BusinessPhoneTypeEnum.Mobile],
    label: 'Mobile'
  },
  {
    value: BusinessPhoneTypeEnum[BusinessPhoneTypeEnum.Business],
    label: 'Business'
  }
];

export const BusinessTypeOptions: SelectOption<string>[] = [
  {
    value: BusinessTypeEnum[BusinessTypeEnum.SoleProprietorship],
    label: 'Sole Proprietorship'
  },
  {
    value: BusinessTypeEnum[BusinessTypeEnum.Partnership],
    label: 'Partnership'
  },
  {
    value: BusinessTypeEnum[BusinessTypeEnum.Association],
    label: 'Association'
  },
  {
    value: BusinessTypeEnum[BusinessTypeEnum.Corporation],
    label: 'Corporation'
  }
];

export const BusinessRoleTypeOptions: SelectOption<string>[] = [
  {
    value: BusinessRoleTypeEnum[BusinessRoleTypeEnum.Owner],
    label: 'Owner'
  },
  {
    value: BusinessRoleTypeEnum[BusinessRoleTypeEnum.Partner],
    label: 'Partner'
  },
  {
    value: BusinessRoleTypeEnum[BusinessRoleTypeEnum.SigningOfficer],
    label: 'Signing Officer'
  },
  {
    value: BusinessRoleTypeEnum[BusinessRoleTypeEnum.BeneficialOwner],
    label: 'Beneficial Owner'
  },
  {
    value: BusinessRoleTypeEnum[BusinessRoleTypeEnum.Director],
    label: 'Director'
  }
];

export const PrefferedContactOptions: SelectOption<string>[] = [
  {
    value: ContactTypeEnum[ContactTypeEnum.Email],
    label: 'Email'
  },
  {
    value: ContactTypeEnum[ContactTypeEnum.Phone],
    label: 'Phone'
  }
];

export const SaluationOptions: SelectOption<string>[] = [
  {
    value: SaluationEnum[SaluationEnum.Mr],
    label: 'Mr'
  },
  {
    value: SaluationEnum[SaluationEnum.Mrs],
    label: 'Mrs'
  },
  {
    value: SaluationEnum[SaluationEnum.Ms],
    label: 'Ms'
  },
  {
    value: SaluationEnum[SaluationEnum.Miss],
    label: 'Miss'
  },
  {
    value: SaluationEnum[SaluationEnum.Dr],
    label: 'Dr'
  },
  {
    value: SaluationEnum[SaluationEnum.Rev],
    label: 'Rev'
  },
  {
    value: SaluationEnum[SaluationEnum.Mx],
    label: 'Mx'
  },
  {
    value: SaluationEnum[SaluationEnum.Jr],
    label: 'Jr'
  },
  {
    value: SaluationEnum[SaluationEnum.Mdme],
    label: 'Mdme'
  },
  {
    value: SaluationEnum[SaluationEnum.Mlle],
    label: 'Mlle'
  },
  {
    value: SaluationEnum[SaluationEnum.M],
    label: 'M'
  },
  {
    value: SaluationEnum[SaluationEnum.Sr],
    label: 'Sr'
  }
];

export const ProspectStatusOptions: SelectOption<string>[] = [
  {
    value: ProspectStatusEnum[ProspectStatusEnum.Active],
    label: 'Active'
  },
  {
    value: ProspectStatusEnum[ProspectStatusEnum.Closed],
    label: 'Closed'
  }
];
