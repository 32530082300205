import { MemberContactDto } from '@core/services/dto/member-contact.dto';

export class MemberContactModel {
  id: number;
  isFavorite: boolean;
  isDefault: boolean;
  subType: string;
  title: string;
  type: string;
  value: string;

  constructor(dto: MemberContactDto) {
    this.id = dto.id;
    this.isFavorite = dto.isFavorite;
    this.isDefault = dto.isDefault;
    this.subType = dto.subType || 'N/A';
    this.title = dto.title || 'N/A';
    this.type = dto.type || 'N/A';
    this.value = dto.value || 'N/A';
  }
}
