import { SMSTemplateDto } from '@core/services/dto/sms-template.dto';

export class SearchSMSTemplatesOptionsModel {
  label: string;
  value: string;
  ref: number;
  type: string;

  constructor(dto: SMSTemplateDto) {
    this.label = `${dto.name}` ;
    this.value = dto.subject;
    this.ref = dto.id;
    this.type = dto.type;
  }
}
