import { ImportOfferDto } from '@core/services/dto';
import { ConversationImportStatusEnum } from '@shared/enums/conversation-import-status.enum';
import { OfferImportStatusEnum } from '@shared/enums/offer-import-status.enum';
import { formatDateSimple } from '@shared/utils';

export class ImportOfferModel {
  file: {
    id: number;
    name: string;
  };
  id: number;
  status: string;
  imported: string;
  importedBy: string;
  fileName: string;

  constructor(dto: ImportOfferDto) {
    this.file = dto.file;
    this.id = dto.id;
    this.imported = dto.imported
      ? formatDateSimple(dto.imported)
      : dto.imported;
    this.importedBy = dto.importedBy
      ? `${dto.importedBy?.firstName} ${dto.importedBy?.lastName}`
      : '';
    this.fileName = dto.file?.name;
    this.status = dto.status;
  }

  isReadyForImport(): boolean {
    return OfferImportStatusEnum[this.status] === OfferImportStatusEnum.Ready;
  }

  isImportInProcess(): boolean {
    return (
      OfferImportStatusEnum[this.status] === OfferImportStatusEnum.InProgress
    );
  }
}
