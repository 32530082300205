export enum TaskTypeEnum {
  General = 1,
  Email = 2,
  Phone = 3,
  FollowUp = 4,
  SMS = 5,
  All = 6
}

export const TaskTypeStringEnum = {
  General: 'General',
  Email: 'E-mail',
  Phone: 'Phone',
  FollowUp: 'Follow Up',
  SMS: 'SMS'
};
