export class AssigneeNameModel {
  firstName: string;
  lastName: string;
  id: string;
    name: string;
    isGroup: string;
    status: string;
    type: string;

  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.name = '';
    this.status = '';
    this.isGroup = '';
    this.id = null;
  }
}
