import { map, tap } from 'rxjs/operators';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OfferApiService, EmailTemplatesApiService } from '@core/services/api.services';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { OfferTemplate } from '@shared/models/offer-template';
import { OfferTemplateIconsDto } from '@core/services/dto';
import { OfferTemplateIcons } from '@shared/models';

@Injectable({ providedIn: 'any' })

export class OfferTemplateModalDialogStateService {

    private data: BehaviorSubject<OfferTemplateIconsDto[]> =
        new BehaviorSubject<OfferTemplateIconsDto[]>(null);

    constructor(
        private service: OfferApiService
    ) {

    }

    getTaskTemplate(id: number): Observable<OfferTemplate> {
        return this.service.getOfferTemplate(id)
            .pipe(
                map(offerTemplateDto => new OfferTemplate(offerTemplateDto))
            );
    }

    getOfferTemplateIcons(): Observable<any> {
        return this.service.getOfferTemplateIcons();
    }

    createOfferTemplate(offerTemplate: OfferTemplate): Observable<OfferTemplate> {
       return this.service.createOfferTemplate(offerTemplate);
    }

    updateOfferTemplate(id: number, offerTemplate: OfferTemplate): Observable<OfferTemplate> {
        return this.service.updateOfferTemplate(id, offerTemplate);
    }
}
