import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EmailTemplateDto } from '@core/services/dto';
import { SMSTemplateDto } from '@core/services/dto/sms-template.dto';
import { SearchEmailTemplatesOptionsModel } from '@shared/models';
import { SearchSMSTemplatesOptionsModel } from '@shared/models/search.sms-templates.options.model';
import { DATE_WITH_TIME_FORMAT, formatDateToLocalSimple } from '@shared/utils';

@Component({
  selector: 'app-email-template-list',
  templateUrl: './email-template-list.component.html',
  styleUrls: ['./email-template-list.component.scss']
})
export class EmailTemplateListComponent implements OnChanges {
  @Input() templates: EmailTemplateDto[] | SearchEmailTemplatesOptionsModel[];
  @Input() templateSms: SMSTemplateDto[] | SearchSMSTemplatesOptionsModel[];
  @Input() mode: string;
  @Input() isSendDisabled: boolean;
  @Input() memberError = false;
  @Input() TemplateType: string;

  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendEmail: EventEmitter<EmailTemplateDto | SMSTemplateDto> = new EventEmitter<EmailTemplateDto | SMSTemplateDto>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.templates && changes.templates.currentValue) {
      if (this.templates && this.templates.length && this.mode === 'view') {
        if (this.TemplateType === 'Email') {
          (this.templates as EmailTemplateDto[]).forEach((item: EmailTemplateDto) => {
            item.sendDate = item.sendDate ? formatDateToLocalSimple(item.sendDate, DATE_WITH_TIME_FORMAT) : null;
          });
        } else if (this.TemplateType === 'SMS') {
          (this.templateSms as SMSTemplateDto[]).forEach((item: SMSTemplateDto) => {
            item.sendDate = item.sendDate ? formatDateToLocalSimple(item.sendDate, DATE_WITH_TIME_FORMAT) : null;
          });
        }
      }
    }
  }

  send(event: EmailTemplateDto | SMSTemplateDto): void {
    this.sendEmail.emit(event);
  }

  remove(event: EmailTemplateDto | SMSTemplateDto): void {
    this.delete.emit(event);
  }
}
