import { SavingDto } from '@core/services/dto';

export class SavingModel {
  accountBalance: number;
  accountCharges: number;
  accountNumber: string;
  accountId: number | string;
  annualFee: number;
  authorizedOverdraft: number;
  availableBalance: number;
  interestRate: number;
  branch: string;
  currency: string;
  openedDate: string;
  status: string;
  subType: string;
  type: string;
  name: string;

  constructor(dto: SavingDto) {
    this.accountBalance = dto.accountBalance || 0;
    this.accountCharges = dto.accountCharges || 0;
    this.accountNumber = dto.accountNumber || '';
    this.accountId = dto.accountId || '';
    this.annualFee = dto.annualFee || 0;
    this.authorizedOverdraft = dto.authorizedOverdraft || 0;
    this.availableBalance = dto.availableBalance || 0;
    this.interestRate = dto.interestRate;
    this.openedDate = dto.openedDate;
    this.branch = dto.branch;
    this.currency = dto.currency;
    this.status = dto.status;
    this.subType = dto.subType;
    this.type = dto.type;
    this.name = dto.name || 'Non-Lending';
  }
}
