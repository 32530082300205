export enum TaskTemplateUseTypeEnum {
  All = 1,
  Workflow = 2,
  Task = 3
}

export const TaskTemplateUseTypeStringEnum = {
  All: 'All',
  Workflow: 'Workflows',
  Task: 'Tasks'
};
