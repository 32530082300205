import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ContentChild, EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional, Output,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NgControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';

import { coerceNumberProperty } from '@angular/cdk/coercion';
import { debounceTime } from 'rxjs/operators';
import { SearchMemberOptionsModel } from '@shared/models/search.member.options.model';
import { Subject, Observable } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';

/**
 * Validates if the value passed has a code in order to be declared as an
 * object provided by material autocomplete options
 */
function isAutocompleteOption(value: any): boolean {
  if (!value || typeof value === 'string') {
    return false;
  }
  return value.id > 0;
}

/**
 * Validates the control value to have an `id` attribute. It is expected
 * control value to be an object.
 */
function containsIdValidation(control: AbstractControl): ValidationErrors {
  return isAutocompleteOption(control.value) ? null : { required: false };
}

@Component({
  selector: 'app-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputAutocompleteComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() placeholder = '';
  @Input() options: SearchMemberOptionsModel[];
  @Input() defaultValue: Observable<SearchMemberOptionsModel | string> = new Observable<SearchMemberOptionsModel | string>();
  @Input() icon = '';
  @Input() showIcon = true;
  @Input() showPlusButton  = false;

  // Inner form control to link input text changes to mat autocomplete
  inputControl = new FormControl('', this.validators);
  noResults = false;
  isSearching = false;
  result: any;
  isSelected = false;

  GLOBAL_SETTINGS = GLOBAL_SETTINGS;

  // eslint-disable-next-line
  private _lengthToTriggerSearch = GLOBAL_SETTINGS.minLengthToTriggerSearch;

  @Input()
  set lengthToTriggerSearch(value: number) {
    this._lengthToTriggerSearch = coerceNumberProperty(value, 0);
  }

  @Output()
  clicked: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  selectedItem: EventEmitter<number> = new EventEmitter<number>();

  @ContentChild('rowTemplate', { read: TemplateRef }) rowTemplateDef: TemplateRef<any>;
  @ViewChild(MatAutocompleteTrigger) matAutocompleteTrigger: MatAutocompleteTrigger;

  addInstance(): void {
    if (this.inputControl.value && this.isSelected) {
      this.clicked.emit(this.inputControl.value as any);
    }
  }

  selected(): void {
    if (this.showPlusButton) {
      this.isSelected = true;
      this.selectedItem.emit(this.inputControl.value as any);
      this.addInstance();
    }
    else {
      this.isSelected = true;
      this.selectedItem.emit(this.inputControl.value as any);
    }
  }

  constructor(
    @Optional() @Self() private controlDir: NgControl,
    private changeDetectorRef: ChangeDetectorRef,
  ) {

    if (this.controlDir) {
      this.controlDir.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);

    if (this.controlDir) {
      // Set validators for the outer ngControl equals to the inner
      const control = this.controlDir.control;
      const validators = control.validator
        ? control.validator
        : this.inputControl.validator;
      this.inputControl.setValidators(validators);
      // Update outer ngControl status
      control.updateValueAndValidity({ emitEvent: false });
    }
    this.defaultValue.subscribe(value => {
      if (typeof value === 'object') {
        this.inputControl.setValue(value as any);
      }
      else {
        this.inputControl.setValue({
          label: value,
          value: '',
          ref: '',
          type: ''
        } as any);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      if (this.isSearching) {
        this.noResults = (!changes.options.firstChange && !changes.options.currentValue?.length);
        this.isSearching = false;
      }
    }
  }

  /**
   * Allows Angular to update the inputControl.
   * Update the model and changes needed for the view here.
   */
  writeValue(obj: any): void {
    // eslint-disable-next-line
    obj && this.inputControl.setValue(obj);
  }

  /**
   * Allows Angular to register a function to call when the inputControl changes.
   */
  registerOnChange(fn: any): void {
    // Pass the value to the outer ngControl if it has an id otherwise pass null
    this.inputControl.valueChanges.pipe(debounceTime(300)).subscribe({
      next: value => {
        if (typeof value === 'string') {
          if (this.isMinLength(value)) {
            this.isSearching = true;
            /**
             * Fire change detection to display the searching status option
             */
            this.changeDetectorRef.detectChanges();
            fn(value);
          } else {
            this.isSearching = false;
            this.noResults = false;

            fn(null);
          }
        } else {
          fn(value);
        }
      },
    });
  }

  /**
   * Allows Angular to register a function to call when the input has been touched.
   * Save the function as a property to call later here.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Allows Angular to disable the input.
   */
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  /**
   * Function to call when the input is touched.
   */
  onTouched(): void {
  }

  /**
   * Method linked to the mat-autocomplete `[displayWith]` input.
   * This is how result name is printed in the input box.
   */
  displayFn(result: any): string | undefined {
    this.result = result;
    return result ? result.label : undefined;
  }

  isMinLength(value: string): boolean {
    return value.length >= this._lengthToTriggerSearch;
  }

  clear(): void {
    this.inputControl.setValue(null);
    this.isSelected = false;
  }

  private scrollEvent = (event: any): void => {
    if (this.matAutocompleteTrigger.panelOpen) {
      this.matAutocompleteTrigger.updatePosition();
    }
  };

  private get validators(): ValidatorFn[] {
    return [];
  }
}
