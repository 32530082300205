import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableColumnModel } from '@shared/components/custom-table/models/TableColumn.model';

@Component({
  selector: 'app-region-builder',
  templateUrl: './region-builder.component.html',
  styleUrls: ['./region-builder.component.scss']
})
export class RegionBuilderComponent {
  @Input() data: any[];
  @Input() tableConfig: TableColumnModel[];
  @Output() clickAction: EventEmitter<any> = new EventEmitter<any>();
  constructor() {
  }

  onIconHandler(state: boolean, item): void {
    item.visible = state;
  }

  emitClick($event): void {
    this.clickAction.emit($event);
  }

}
