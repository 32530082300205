import { SearchNoteDto } from '@core/services/dto';

export class SearchNoteModel {
  data: any[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageNumber: number;
  totalCount: number;
  totalPages: number;

  constructor(dto: SearchNoteDto) {
    this.data = dto.data;
    this.hasNextPage = dto.hasNextPage;
    this.hasPreviousPage = dto.hasPreviousPage;
    this.pageNumber = dto.pageNumber;
    this.totalCount = dto.totalCount;
    this.totalPages = dto.totalPages;
  }
}
