<div class="root sm-font-container">
  <form [formGroup]="form" fxLayout="row wrap" fxLayoutGap="15px 0 grid">

    <mat-form-field fxFlex="1 1 40" appearance="outline">
      <mat-label>Template Type</mat-label>
      <mat-select formControlName="useType">
        <mat-option *ngFor="let item of useTypeOptions" [value]="item.value">
          {{item.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="1 1 60">
      <mat-label>Task Template Name</mat-label>
      <input matInput placeholder="Task Template Name" formControlName="name">
    </mat-form-field>
    <mat-form-field fxFlex="1 1 40" appearance="outline">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option *ngFor="let item of statusOptions" [value]="item.value">
          {{item.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="1 1 60" appearance="outline">
      <mat-label>Task Type</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let item of typeOptions" [value]="item.value">
          {{item.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="1 1 40" appearance="outline">
      <mat-label>Priority</mat-label>
      <mat-select formControlName="priority">
        <mat-option *ngFor="let item of priorityOptions" [value]="item.value">
          {{item.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <ng-container *ngIf="isEdit" >
        <mat-form-field appearance="outline" [style.width.%]="130">
          <mat-label>Assigned To</mat-label>
          <input type="text" (input)="onchangemat()" formControlName="assigneeId" matInput placeholder="Assigned To"
            [matAutocomplete]="auto"
            [(ngModel)]="selectedUser">
          <mat-icon matSuffix><i class="icon-search"></i></mat-icon>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
            <mat-option *ngFor="let user of conversationUsers$ | async" [value]="user"
              [disabled]="user.status === 'Inactive'"
              [ngStyle]="{'color': user.status === 'Inactive' ? 'gray' : 'initial'}">
              {{ user?.label}}

            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="!isEdit">
        <mat-form-field appearance="outline" [style.width.%]="130">
          <mat-label>Assigned To</mat-label>
          <input type="text" (input)="onchangemat()" formControlName="assigneeId" matInput placeholder="Assigned To">
          <mat-icon matSuffix><i class="icon-search"></i></mat-icon>
          <mat-autocomplete #myAutocomplete="matAutocomplete" [displayWith]="getOptionText">
            <mat-option *ngFor="let user of conversationUsers$ | async" [value]="user"
              [disabled]="user.status === 'Inactive'"
              [ngStyle]="{'color': user.status === 'Inactive' ? 'gray' : 'initial'}">
              {{ user?.label}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>

    <!-- <app-input-autocomplete formControlName="assigneeId"
                                  placeholder="Assigned to"
                                  [options]="conversationUsers$ | async"
                                  [defaultValue]="defaultAssigneeUserName"
                                  icon="search">
          </app-input-autocomplete> -->

    <mat-form-field fxFlex="100" appearance="outline">
      <mat-label>Description</mat-label>
      <textarea formControlName="description" rows="3" matInput></textarea>
    </mat-form-field>
    <div fxLayoutAlign="start end">
      <div fxFlex="1 1 60">
        <div class="due-in" fxLayout="row wrap">
          <span fxFlex="100">Due in (Optional)</span>
          <div fxLayout="row wrap" fxLayoutGap="15px 0 grid">
            <mat-form-field appearance="outline" fxFlex="1 1 20">
              <input matInput (change)="updateDueIn()" formControlName="dueIn" type="number" placeholder="1">
            </mat-form-field>
            <mat-form-field fxFlex="1 2 35" appearance="outline">
              <mat-select formControlName="dueDateType">
                <mat-option *ngFor="let item of dueDateType" [value]="item.value">
                  {{item.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

    </div>
    <div fxFlex="100%">
      <app-add-email-template *ngIf="isEmailType()" [preselected]="preselectedEmailTemplates"
        (chosenEmail)="addEmail($event)"></app-add-email-template>
    </div>
    <div fxFlex="100%">
      <app-add-sms-template *ngIf="isSMSType()" [preselected]="preselectedSmsTemplates"
        (chosenSMS)="addSMS($event)"></app-add-sms-template>
    </div>
    <div *ngIf="!isEditMode">
      <app-upload-file [readOnly]="false" [fileType]="FileTypeEnum.Crm" [parentForm]="form"></app-upload-file>
    </div>

    <div *ngIf="isEditMode && files">
      <app-upload-file [readOnly]="false" [fileType]="FileTypeEnum.Crm" [parentForm]="form"
        [files]="files"></app-upload-file>
    </div>

  </form>
  <div *ngIf="Id" fxLayout="row" fxLayoutAlign="end" class="mt">
    <span class="cancel" (click)="this.closeDialog.emit()">Cancel</span>
    <cm-button color="primary" (click)="form.valid && record()" [disabled]="!form.valid">
      Save
    </cm-button>
  </div>
  <div *ngIf="!Id">
    <cm-button color="primary" class="record-btn" (click)="form.valid && record()" [disabled]="!form.valid">
      Save Task Template
    </cm-button>
  </div>
</div>