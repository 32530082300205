import { Injectable } from '@angular/core';
import { CollapseDialogType } from '@shared/models/dialog-type.enum';
import { OpenDialogInterface } from '@shared/models/open-dialog.interface';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class OpenedDialogService {
  private openedDialog = new BehaviorSubject<OpenDialogInterface[]>([]);
  openedDialog$ = this.openedDialog.asObservable();

  addDialog(dialog: OpenDialogInterface): void {
    const currentDialog = this.allOpenDialog();
    const isExist = currentDialog.find((xDialog) => xDialog.id === dialog.id);
    if (isExist) {
      return;
    }
    const dialogs = [dialog, ...currentDialog];
    this.openedDialog.next(dialogs);
  }

  closeDialog(id: string): void {
    const currentDialog = this.allOpenDialog();
    const isExist = currentDialog.find((xDialog) => xDialog.id === id);
    if (!isExist) {
      return;
    }
    const dialogs = currentDialog.filter(
      (xDialog) => xDialog.id !== id
    );
    this.openedDialog.next(dialogs);
  }

  removeAll(): void {
    this.openedDialog.next([]);
  }

  // checkAlreadyOpened(id: string): boolean {
  //   const currentDialog = this.allOpenDialog();
  //   const dialog = currentDialog.find((xDialog) => xDialog.id === id);
  //   if (!dialog) {
  //     return false;
  //   }
  //   dialog.maximizeFn();
  //   return true;
  // }

  allOpenDialog(): OpenDialogInterface[] {
    return this.openedDialog.getValue();
  }

  getDialogIcon(type: CollapseDialogType, mode: string): string {
    if (type === CollapseDialogType.Task) {
      return `icon-${mode}-task`;
    }else if (type === CollapseDialogType.Conversation) {
      return `icon-${mode}-conversation`;
    }else if (type === CollapseDialogType.Notes) {
      return `icon-${mode}-note`;
    }else if (type === CollapseDialogType.Prospects) {
      return `icon-create_prospect`;
    }else if (type === CollapseDialogType.Sale) {
      return `icon-${mode}-sale`;
    }else if (type === CollapseDialogType.Opportunity) {
      return `icon-${mode}-opportunity`;
    }else if (type === CollapseDialogType.Concern) {
      return `icon-${mode}-concern`;
    }
    return '';
  }

  getModalType(type: string): CollapseDialogType {
    if (type === 'task') {
      return CollapseDialogType.Task;
    }else if (type === 'create_conversation') {
      return CollapseDialogType.Conversation;
    }else if (type === 'create_note') {
      return CollapseDialogType.Notes;
    }else if (type === 'create_prospect') {
      return CollapseDialogType.Prospects;
    }else if (type === 'sale') {
      return CollapseDialogType.Sale;
    }
    return CollapseDialogType.None;
  }

  modalTypeIsOpen(type: string): boolean {
    const currentDialog = this.allOpenDialog();
    const dialog = currentDialog.find((xDialog) => xDialog.type === type);
    return dialog ? true : false;
  }

  sameModalTypeIsOpenExceptMe(id: string, type: string): boolean {
    const currentDialog = this.allOpenDialog();
    const dialog = currentDialog.find((xDialog) => xDialog.type === type && xDialog.id !== id);
    return dialog ? true : false;
  }

  detailModalTypeIsOpen(detailId: string): OpenDialogInterface | undefined {
    const currentDialog = this.allOpenDialog();
    const dialog = currentDialog.find((xDialog) => xDialog.detailId === detailId);
    return dialog;
  }

  isAlreadyInMinimized(id: string): boolean {
    const currentDialog = this.allOpenDialog();
    const dialog = currentDialog.find((xDialog) => xDialog.id === id);
    return dialog ? true : false;
  }

  updateDialogType(id: string, type: CollapseDialogType, mode: string): void {
    const currentDialogs = this.allOpenDialog();
    const index = currentDialogs.findIndex((xDialog) => xDialog.id === id);
    currentDialogs[index].type = type;
    currentDialogs[index].icon = this.getDialogIcon(type, mode);
    currentDialogs[index].title = mode === 'create' ? `Pending ${type}` : `${type} Details`;
    this.openedDialog.next(currentDialogs);
  }

}
