import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionStringEnum } from '@shared/enums';
import { AccessService } from '@core/services/access.service';
import { AuthService } from '@core/services/auth';

@Directive({
  selector: '[appCheckPermission]',
})
export class CheckPermissionDirective implements OnInit {
  @Input() appCheckPermission: PermissionStringEnum | PermissionStringEnum[];

  constructor(
    private accessService: AccessService,
    private auth: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit(): void {
    const userPermissions = this.auth.permissions;
    const permissions = Array.isArray(this.appCheckPermission)
      ? this.appCheckPermission
      : [this.appCheckPermission];

    if (
      !!userPermissions &&
      this.accessService.checkUserPermissions(permissions)
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
