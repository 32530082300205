import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AppConfig } from 'app/configs/app.config';
import { Router } from '@angular/router';
import { Member, MemberProfileModel } from '@shared/models';
import { MemberTypeStringEnum } from '@shared/enums';

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss']
})
export class MemberListComponent implements OnInit, AfterViewChecked {

  @Input() className: string;
  @Input() isMemberNumber = false;
  @Input() readOnly = false;
  @Input() linksList: Member[];
  @Input() memberProfile: MemberProfileModel;
  members: Member[];
  initials: string;
  member: Member;
  show = false;
  hideTimeout: any; // Used to manage the hide delay
  id: number;
  links = AppConfig.routes.memberProfile.main;
  prospectlink = AppConfig.routes.prospectProfile.main;
  tempName: string;
  isBusinessAccount: boolean;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    if (this.memberProfile && this.memberProfile.memberNumber) {
      this.tempName = 'recent';
    } else {
      if (this.linksList?.length) {
        this.members = this.linksList.filter(member => member.type === 'Member' || member.type === 'Prospect');
        if (this.members.length) {
          this.member = this.members[0];
          this.id = +this.member.memberId;
          this.tempName = this.members.length === 1 ? 'one' : 'many';
          this.iconGenerator(this.member);
        } else {
          this.tempName = 'zero';
        }
      } else {
        this.tempName = 'zero';
      }
    }
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  showList(): void {
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
    }
    this.show = true;
  }

  hideList(): void {
    this.hideTimeout = setTimeout(() => {
      this.show = false;
    }, 200);
  }

  toggleList(event: Event): void {
    event.stopPropagation();
    this.show = !this.show;
  }

  goToProfile(id: any, event?: Event): void {
    event?.stopPropagation();
    if (this.member.type === 'Prospect') {
      this.router.navigate([`${this.prospectlink}`, id]);
    }
    else {
      this.router.navigate([`/${this.links}`, id]);
    }
  }

  getFullName(member: Member): string {
    if (member.type === 'Prospect') {
      if (member.organizationName) {
        return member.organizationName;
      }
      return member.firstName + ' ' + member.lastName;
    } else if (member.type === 'Member') {
      if (member.organizationName) {
        return member.organizationName;
      }
      return member.firstName + ' ' + member.lastName;
    }

  }

  iconGenerator(member: Member): void {
    if (member.organizationName || member.firstName && member.lastName) {
      if (member.organizationName) {
        this.isBusinessAccount = true;
      } else {
        this.initials = member.firstName.charAt(0).toLocaleUpperCase() + member.lastName.charAt(0).toLocaleUpperCase();
      }
    } else {
      this.tempName = 'zero';
    }
  }

  getFullNameRecentProfile(member: MemberProfileModel): string {
    if (member.memberType === MemberTypeStringEnum.Business || member.memberType === MemberTypeStringEnum.BusinessProspect) {
      return member?.memberInfo?.organizationName;
    } else if (member.memberType === MemberTypeStringEnum.Individual) {
      return member.memberInfo?.firstName + ' ' + member.memberInfo?.lastName;
    }
  }

  iconGeneratorRecentProfile(member: MemberProfileModel): string | void {
    if (member.memberType === MemberTypeStringEnum.Business) {
      this.isBusinessAccount = true;
    } else if (member.memberType === MemberTypeStringEnum.Individual) {
      return member.memberInfo?.firstName.charAt(0).toLocaleUpperCase() + member?.memberInfo?.lastName.charAt(0).toLocaleUpperCase();
    }
  }
}
