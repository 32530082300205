import { Injectable } from '@angular/core';
import { ApiClient } from '@core/services/api-client.http.service';
import { BranchDto, PaginatedResponseDto, UserGroupDetailsDto, UserManagementUsersDto } from '@core/services/dto';
import { ConversationUrls, EcmUrls, MemberUrls, OfferUrls, UserGroupManagementUrls } from '@core/constants';
import { Observable } from 'rxjs';
import { UserGroupManagementGroupsDto } from '@core/services/dto/user-group-management-groups.dto';

@Injectable({
  providedIn: 'any',
})
export class UserGroupManagementApiService {
  constructor(private api: ApiClient) { }

  getGroups(
    request
  ): Observable<PaginatedResponseDto<UserGroupManagementGroupsDto>> {
    return this.api.get<PaginatedResponseDto<UserGroupManagementGroupsDto>>(
      UserGroupManagementUrls.groups,
      request
    );
  }
  addGroup(request): Observable<number> {
    return this.api.post<number>(UserGroupManagementUrls.groups, request);
  }

  editGroup(request, id): Observable<UserGroupDetailsDto> {
    return this.api.put<UserGroupDetailsDto>(
      UserGroupManagementUrls.groupDetails(id),
      request
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete<any>(UserGroupManagementUrls.groupDetails(id));
  }

  getGroup(id: number): Observable<UserGroupDetailsDto> {
    return this.api.get<UserGroupDetailsDto>(
      UserGroupManagementUrls.groupDetails(id)
    );
  }

  getGroupUsers(
    id: number,
    request
  ): Observable<PaginatedResponseDto<UserManagementUsersDto>> {
    return this.api.get<PaginatedResponseDto<UserManagementUsersDto>>(
      UserGroupManagementUrls.groupUsers(id),
      request
    );
  }

  saveUsersToGroup(id, request): Observable<number> {
    return this.api.put<number>(
      UserGroupManagementUrls.groupUsers(id),
      request
    );
  }

  deleteUsersFromGroup(id, groupId): Observable<any> {
    return this.api.delete<any>(
      UserGroupManagementUrls.userGroupDetails(id, groupId)
    );
  }

  createUser(request): Observable<number> {
    return this.api.post<number>(OfferUrls.offerUsers, request);
  }

  getOfferBranch(
    siteId
  ): Observable<PaginatedResponseDto<BranchDto>> {

    return this.api.get(OfferUrls.OfferBranches(siteId));
  }

  isCRMObjectAttached(groupId: number): Observable<any> {
    return this.api.get(ConversationUrls.isCrmObjectExists(groupId));
  }

  checkUserMemberReprensentative(userId: string, groupId: number): Observable<boolean> {
    return this.api.get(MemberUrls.checkUserMemberRepresentativeAdded(userId, groupId));
  }

  checkGroupMemberReprensentative(userGroupId: number): Observable<boolean> {
    return this.api.get(MemberUrls.checkGroupMemberRepresentativeAdded(userGroupId));
  }

  checkECMFolderPermission(groupId: number): Observable<boolean> {
    return this.api.get(EcmUrls.checkECMFolderGroup(groupId));
  }
}
