import { SelectOption } from '@shared/interfaces';
import { ConversationTypeEnum } from '@shared/enums';

export const CONVERSATIONTYPEOPTIONS: SelectOption<string>[] = [
  {
    value: ConversationTypeEnum[ConversationTypeEnum.Conversation],
    label: 'Conversation'
  },
  {
    value: ConversationTypeEnum[ConversationTypeEnum.Opportunity],
    label: 'Opportunity'
  },
  {
    value: ConversationTypeEnum[ConversationTypeEnum.Concern],
    label: 'Concern'
  }
];

export enum ConversationType {
  conversation = 'Conversation',
  concern = 'Concern',
  opportunity = 'Opportunity'
}
