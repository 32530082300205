import { Injectable } from '@angular/core';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchUserOptionsModel } from '@shared/models';
import { map } from 'rxjs/operators';
import { PaginatedResponseDto, UserDto } from '@core/services/dto';
import { UserApiRequest } from '@shared/models/requests/user.request.dto';
import { UserApiService } from '@core/services/api.services/user-api.service';

@Injectable({providedIn: 'any'})

export class ProductCardStateService extends autoUnsubscribeMixin() {
  public soldByUsers: BehaviorSubject<SearchUserOptionsModel[]> = new BehaviorSubject<SearchUserOptionsModel[]>([]);

  soldByUsers$(): Observable<SearchUserOptionsModel[]> {
    return this.soldByUsers.asObservable();
  }

  constructor(
    private userService: UserApiService
  ) {
    super();
  }

  getSearchedUsers(search: string): void {
    this.getUsers(search).pipe(

      map((response) => {

        return {
          ...response,
          data: response.data.map(item => new SearchUserOptionsModel(item))
        };
      })
    ).subscribe(response => {
      this.soldByUsers.next(response.data);
    });

  }

  getUsers(searchString: string): Observable<PaginatedResponseDto<UserDto>> {
    const request: UserApiRequest = { pageSize: 3, search: searchString };
    return this.userService.getUsers(request);
  }

}
