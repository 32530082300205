export enum ActiveInactiveStatusEnum {
  Active = 1,
  Inactive = 2,
}

export enum CampaignTypeStatusEnum {
  Retail = 1,
  Business = 2,
}

export enum AnnouncementTypeStatusEnum {
  Global = 1,
  Branch = 2,
}

export enum BannerPositionEnum {
  header = 1,
  footer = 2,
}

export enum ApplicationsTypeEnum {
  Retail = 1,
  Business = 2,
  All = 3,
}

export enum UserTypeEnum {
  All = -1,
  Full = 1,
  Team = 2,
}

export enum ApplicationsTypeStringEnum {
  Retail = 'Retail',
  Business = 'Business',
  All = 'All',
}

export enum ApplicationsStatusEnum {
  InProgress = 0,
  Pending = 1,
  Cheque_Clearing = 2,
  Completed = 3,
  Rejected = 4,
  Declined = 5,
  Returned = 6,
  ReferredToBranch = 7,
  Verified = 8,
  Abandoned = 9,
  ReferredToBranchComplete = 10,
  AwaitingFunding = 11,
  LendingHalted = 12,
  MortgageLead = 13,
  ManagerReviewRequired = 14,
  InsuranceFailed = 15,
  FundingReceived = 16,
  ClosedTransferred = 17,
  Expired = 18,
  RejectedFraudulent = 19,
  All = 20,
}

export enum ApplicationsStatusStringEnum {
  InProgress = 'In Progress',
  Pending = 'Pending',
  Cheque_Clearing = 'Cheque Clearing',
  Completed = 'Completed',
  Rejected = 'Rejected',
  Declined = 'Declined',
  Returned = 'Returned',
  ReferredToBranch = 'Referred to Branch',
  Verified = 'Verified',
  Abandoned = 'Abandoned/Incomplete',
  ReferredToBranchComplete = 'Referred to branch - Complete',
  AwaitingFunding = 'Awaiting Funding',
  LendingHalted = 'Lending Halted',
  MortgageLead = 'Mortgage Lead',
  ManagerReviewRequired = 'Manager Review required',
  InsuranceFailed = 'Insurance Failed',
  FundingReceived = 'Funding Received',
  ClosedTransferred = 'Closed - Transferred',
  Expired = 'Expired',
  RejectedFraudulent = 'Rejected - Fraudulent',
  All = 'All',
}

export enum UserReportingStatusEnum {
  All = -1,
  Deleted = 1,
  New = 2,
  Pending = 3,
  Disabled = 4,
  Active = 5,
}

export enum OfferStatusEnum {
  All = 0,
  Active = 1,
  Inactive = 2,
  Fulfilled = 3,
  Closed = 4,
  Expired = 5,
  Deleted = 6,
  Declined = 7,
}

export enum ApprovalModelStatusEnum {
  All = 0,
  Active = 1,
  Inactive = 2,
}

export enum ModelTypeApprovalModelStatusEnum {
  All = 0,
  Business = 1,
  Retail = 2,
}

export enum ProductTypeApprovalModelStatusEnum {
  All = 0,
  Loan = 1,
  LOC = 2,
  Mortgage = 3,
}

export enum RateModelStatusEnum {
  All = 0,
  Active = 1,
  Inactive = 2,
}

export enum ModelTypeRateModelStatusEnum {
  All = 0,
  Business = 1,
  Retail = 2,
}

export enum ProductTypeRateModelStatusEnum {
  All = 0,
  Loan = 1,
  LOC = 2,
  Mortgage = 3,
}

export enum RateTypeRateModelStatusEnum {
  All = 0,
  Fixed = 1,
  Variable = 2,
}
