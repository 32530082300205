import { AttachmentModel } from '@shared/models/attachment.model';
import { WorkflowTaskModel } from '@shared/models/index';

export class WorkflowModel {
  id?: number;
  name: string;
  description: string;
  createdDate?: string;
  status: string;
  completionDue: {
    dueIn: number,
    dueDateType: string
  };
  attachments?: AttachmentModel[];
  tasks?: WorkflowTaskModel[];
}
