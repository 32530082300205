import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { CustomSnackbarService } from '@core/services/custom-snackbar.service';
import { catchError, map, tap } from 'rxjs/operators';
import { createSearchParameters } from '@shared/utils';
import { MsalService } from '@azure/msal-angular';
import { SpinnerService } from '@shared/services/spinner.service';

export type RequestParams = {
  [param: string]: any;
};

export interface FileWithName {
  data: HttpResponse<Blob>;
  name: string;
}

@Injectable({
  providedIn: 'root'
})

export class ApiClient {
  constructor(
    private http: HttpClient,
    private toast: CustomSnackbarService,
    private msalService: MsalService,
    private spinner: SpinnerService,
  ) {
  }

  public get<T>(url: string, request?: RequestParams, headers?: HttpHeaders, hiddenSpinner?): Observable<T> {
    if (!hiddenSpinner) { this.spinner.show(); }
    const params = createSearchParameters(request);

    return this.http.get<T>(url, { params, headers }).pipe(
      tap(() => this.spinner.hide()),
      catchError(this.handleError.bind(this)),
    );
  }

  public getFile<T>(url: string, hiddenSpinner?, request?: RequestParams): Observable<Blob> {
    if (!hiddenSpinner) { this.spinner.show(); }
    const params = createSearchParameters(request);
    return this.http.get(url, { params, responseType: 'blob' }).pipe(
      tap(() => this.spinner.hide()),
      catchError(this.handleError.bind(this)),
    );
  }

  public getFileWithName<T>(url: string, hiddenSpinner?, request?: RequestParams): Observable<FileWithName> {
    if (!hiddenSpinner) { this.spinner.show(); }
    const params = createSearchParameters(request);
    return this.http.get(url, { params, observe: 'response', responseType: 'blob' }).pipe(
      map((data) => {
          const contentDisposition = data.headers.get('content-disposition');
          const contentDispositionItems = contentDisposition.split('\'');
          const name = contentDispositionItems[contentDispositionItems.length - 1];
          return { data, name };
      }),
      tap(() => this.spinner.hide()),
      catchError(this.handleError.bind(this)),
    );
  }

  public export<T>(url: string, hiddenSpinner?, query?: string): Observable<Blob> {
    if (!hiddenSpinner) { this.spinner.show(); }
    return this.http.put(url, query, { responseType: 'blob' }).pipe(
      tap(() => this.spinner.hide()),
      catchError(this.handleError.bind(this)),
    );
  }

  public post<T>(url: string, request?: RequestParams, hiddenSpinner?): Observable<T> {
    if (!hiddenSpinner) { this.spinner.show(); }
    return this.http.post<T>(url, request).pipe(
      tap(() => this.spinner.hide()),
      catchError(this.handleError.bind(this)),
    );
  }

  public put<T>(url: string, request?: RequestParams, hiddenSpinner?): Observable<T> {
    if (!hiddenSpinner) { this.spinner.show(); }
    return this.http.put<T>(url, request).pipe(
      tap(() => this.spinner.hide()),
      catchError(this.handleError.bind(this)),
    );
  }

  public delete<T>(url: string, request?: RequestParams, hiddenSpinner?): Observable<T> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: request,
    };
    if (!hiddenSpinner) { this.spinner.show(); }
    return this.http.delete<T>(url, options).pipe(
      tap(() => this.spinner.hide()),
      catchError(this.handleError.bind(this)),
    );
  }

  private handleError(err: HttpErrorResponse | ErrorEvent | any): any {
    let message = 'Something went wrong, please try again';
    let status: number;
    let errors: any;
    if (err && err.status === 200 || err && err.status === 403) {
      this.spinner.hide();
      return of(err.error.text);
    }
    if (err && err.status === 500) {
      message = err.error.detail ? err.error.detail : err.error.title;
    }
    if (err && err.status === 401) {
      message = 'Token authentication failed. Please login again';
      localStorage.removeItem('inner_token');
      this.msalService.logout();
    } else if (err && err.error && err.error.status !== 500) {
      if (err.error.status === 400) {
        message = err.error.title ? err.error.title : err.error.detail;
      } else {
        message = err.error.detail;
      }
      status = err.error.status;
      errors = err.error.errors;
    }
    this.toast.fail(message);

    const errorObj: object = { message, status, errors };
    this.spinner.hide();

    return throwError(errorObj);
  }
}
