import { Observable, of } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeInnerHtml' })
export class RemoveInnerHtmlPipe implements PipeTransform {
  transform(value): Observable<string> {
    const formattedValue = value.replace(/<[^>]*>?/gm, '');
    return of(formattedValue);
  }
}
