import { Injectable } from '@angular/core';
import { ApiClient } from '@core/services/api-client.http.service';
import { ProductUrls } from '@core/constants';
import { CampaignsListDto } from '@core/services/dto/campaigns-list.dto';
import { PaginatedResponseDto } from '@core/services/dto';
import { Observable } from 'rxjs';
import { CreateCampaignRequestDto } from '@shared/models/requests/create-campaign.request.dto';

@Injectable({
  providedIn: 'root'
})

export class CampaignManagementApiService {

  constructor(private api: ApiClient) {
  }

  createCampaign(request: CreateCampaignRequestDto): Observable<number> {
    return this.api.post<number>(ProductUrls.campaigns, request);
  }

  editCampaign(id: number, request: CreateCampaignRequestDto): Observable<number> {
    return this.api.put<number>(ProductUrls.campaign(id), request);
  }

  getCampaigns(request): Observable<PaginatedResponseDto<CampaignsListDto>> {
    return this.api.get<PaginatedResponseDto<CampaignsListDto>>(ProductUrls.campaigns, request);
  }

  deleteCampaign(id: number): Observable<number> {
    return this.api.delete<number>(ProductUrls.campaign(id));
  }
}
