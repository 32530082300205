import {
  Component,
  Input,
  QueryList,
  TemplateRef,
  ViewContainerRef,
  AfterViewInit,
  ViewChildren,
  Output,
  EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss']
})
export class TableFilterComponent implements AfterViewInit {
  @Input() filterRows: QueryList<TemplateRef<any>>;
  @Input() showSearchBtn = false;
  @Input() showSwitchBtn = false;

  @ViewChildren('row', { read: ViewContainerRef }) rows: QueryList<ViewContainerRef>;
  @Output() clickSearch: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const rows = this.filterRows.toArray();
      this.rows.forEach((r, index) => r.createEmbeddedView(rows[index]));
    }, 0);
  }

  search(): void {
    this.clickSearch.emit();
  }
}
