import { OfferTemplateDto } from '@core/services/dto';

export class OfferTemplate {
  id?: number;
  offerType: string;
  templateName: string;
  status: string;
  title: string;
  summary: string;
  description: string;
  offerLinkType: string;
  redirectLink: string;
  offerCallToAction: string;
  termAndCondition: string;
  offerAmount: number;
  offerRate: number;
  icon: string;
  bannerImage: string;
  bannerImageId: number;
  productSku: string;
  createdBy?: {
    userId: string;
    firstName: string;
    lastName: string;
  };
  timeDuration?: {
    dueIn: number;
    dueDateType: string;
  };

  constructor(dto: OfferTemplateDto) {
    this.id = dto.id;
    this.offerType = dto.offerType;
    this.templateName = dto.templateName;
    this.description = dto.description;
    this.status = dto.status;
    this.title = dto.title;
    this.summary = dto.summary;
    this.offerLinkType = dto.offerLinkType;
    this.redirectLink = dto.redirectLink;
    this.offerCallToAction = dto.offerCallToAction;
    this.termAndCondition = dto.termAndCondition;
    this.offerAmount = dto.offerAmount;
    this.offerRate = dto.offerRate;
    this.icon = dto.icon;
    this.timeDuration = dto.timeDue;
    this.bannerImage = dto.bannerImage;
    this.bannerImageId = dto.bannerImageId;
    this.productSku = dto.productSku;
  }
}
