export const AccountTypeStringEnum = {
  Lending: 'Lending',
  Mortgage: 'Mortgage',
  Savings: 'Savings',
  Chequing: 'Chequing',
  LineOfCredit: 'Line of Credit',
  TermDeposit: 'Term Deposit'
};

export const AccountTypeEnum = {
  Lending: 'Lending',
  Mortgage: 'Mortgage',
  Savings: 'Savings',
  Chequing: 'Chequing',
  LineOfCredit: 'LineOfCredit',
  TermDeposit: 'TermDeposit'
};
