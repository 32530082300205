import { Component, Input } from '@angular/core';
import { TheeDotsMenuPalette } from '@shared/components/three-dots-menu/three-dots-menu.constants';

@Component({
  selector: 'app-three-dots-menu',
  templateUrl: './three-dots-menu.component.html',
  styleUrls: ['./three-dots-menu.component.scss']
})
export class ThreeDotsMenuComponent {
  @Input() color: TheeDotsMenuPalette = 'primary';
}
