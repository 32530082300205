import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { CustomSnackbarService } from '@core/services/custom-snackbar.service';
import {
  OfferTemplateIconsDto,
  ProductDto,
  SalesProductDto,
} from '@core/services/dto';
import { GlobalStateService } from '@core/services/global.state.service';
import {
  TypeOptions,
  DueDateType,
} from '@shared/components/offer-template-model-dialog/offer-template-modal-dialog.config';
import { STATUS_ACTIVE_INACTIVE_OPTIONS } from '@shared/constants/select-options.constants';
import {
  OfferTypeEnum,
  OfferTypeStringEnum,
} from '@shared/enums/offer-type.enum';
import {
  AttachmentModel,
  OfferTemplateIcons,
  ProductModel,
  ProductTypeModel,
} from '@shared/models';
import { OfferTemplate } from '@shared/models/offer-template';
import { OfferTemplateModalDialogStateService } from './offer-template-modal-dialog.state.service';
import { FileTypeEnum } from '@shared/enums';
import { OfferBannerAttachmentModel } from '@shared/models/offer-banner-attachment.model';
import { OfferStatusEnum } from '@shared/enums/offer-status.enum';
import { Editor, toDoc, toHTML, Toolbar } from 'ngx-editor';
import { OfferToolbarConfig } from '@shared/configs/editor.config';

@Component({
  selector: 'app-offer-template-model-dialog',
  templateUrl: './offer-template-model-dialog.component.html',
  styleUrls: ['./offer-template-model-dialog.component.scss'],
})
export class OfferTemplateModelDialogComponent
  extends autoUnsubscribeMixin()
  implements OnInit, AfterViewInit {
  form: FormGroup;
  isDisabled = false;
  statusOptions = STATUS_ACTIVE_INACTIVE_OPTIONS;
  typeOptions = TypeOptions;
  dueDateType = DueDateType;
  offerTemplate: OfferTemplate;
  files: OfferBannerAttachmentModel[];
  offerTemplateIcons: OfferTemplateIcons;
  isEditMode = false;
  FileTypeEnum = FileTypeEnum;
  editor: Editor;
  toolbar: Toolbar;
  editortc: Editor;
  toolbartc: Toolbar;

  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Input() Id?;

  constructor(
    private fb: FormBuilder,
    private globalState: GlobalStateService,
    private state: OfferTemplateModalDialogStateService,
    private toast: CustomSnackbarService
  ) {
    super();
  }

  ngOnInit(): void {
    this.toolbar = OfferToolbarConfig;
    this.toolbartc = OfferToolbarConfig;
    this.form = this.fb.group({
      templateName: ['', [Validators.maxLength(50), Validators.required]],
      status: [OfferStatusEnum[OfferStatusEnum.Active], Validators.required],
      title: ['', [Validators.maxLength(50), Validators.required]],
      offerType: [OfferTypeEnum[OfferTypeEnum.Offer], Validators.required],
      summary: ['', [Validators.maxLength(1000), Validators.required]],
      description: ['', [Validators.maxLength(1000)]],
      redirectLink: null,
      dueIn: ['', [Validators.max(99), Validators.required]],
      dueDateType: ['Days', Validators.required],
      offerLinkType: ['URL'],
      offerCallToAction: ['', [Validators.max(299)]],
      termAndCondition: ['', [Validators.maxLength(1000)]],
      icon: [''],
      offerAmount: null,
      offerRate: [null, Validators.max(100)],
      bannerImage: [''],
      bannerImageId: null,
      productSku: null,
    });

    this.state.getOfferTemplateIcons().subscribe((data) => {
      this.offerTemplateIcons = data;
    });

    this.editor = new Editor();
    this.form.get('description').valueChanges.subscribe((body) => {
      if (
        body &&
        typeof this.form.get('description').value === 'object' &&
        typeof this.form.get('description').value !== null
      ) {
        if (toHTML(this.form.get('description').value) === '<p></p>') {
          this.form.get('description').setValue(null);
        }
      }
    });

    this.editortc = new Editor();
    this.form.get('termAndCondition').valueChanges.subscribe((body) => {
      if (
        body &&
        typeof this.form.get('termAndCondition').value === 'object' &&
        typeof this.form.get('termAndCondition').value !== null
      ) {
        if (toHTML(this.form.get('termAndCondition').value) === '<p></p>') {
          this.form.get('termAndCondition').setValue(null);
        }
      }
    });

    if (this.Id) {
      this.isEditMode = true;
      this.state.getTaskTemplate(this.Id).subscribe((task) => {
        this.offerTemplate = task;
        this.form.patchValue({
          templateName: this.offerTemplate.templateName || '',
          title: this.offerTemplate.title || '',
          status: this.offerTemplate.status || '',
          offerType: this.offerTemplate.offerType || '',
          description: this.offerTemplate.description || '',
          summary: this.offerTemplate.summary || '',
          redirectLink: this.offerTemplate.redirectLink || '',
          offerLinkType: this.offerTemplate.offerLinkType || '',
          termAndCondition: this.offerTemplate.termAndCondition || '',
          offerCallToAction: this.offerTemplate.offerCallToAction || '',
          icon: this.offerTemplate.icon || '',
          offerAmount: this.offerTemplate.offerAmount || null,
          offerRate: this.offerTemplate.offerRate || null,
          dueIn: this.offerTemplate.timeDuration.dueIn || '',
          dueDateType: this.offerTemplate.timeDuration.dueDateType || '',
          bannerImage: this.offerTemplate.bannerImage || '',
          bannerImageId: this.offerTemplate.bannerImageId || null,
          productSku: this.offerTemplate.productSku || null,
        });
      });
    }
  }

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe(val => {
      const redirectLink = 'redirectLink';
      const productSku = 'productSku';
      if (val.offerType === OfferTypeStringEnum.Offer) {
        if (val.offerLinkType === 'URL') {
          this.form.controls[redirectLink].setValidators([Validators.required]);
          this.form.controls[redirectLink].updateValueAndValidity({ emitEvent: false });
        } else {
          this.form.controls[redirectLink].clearValidators();
          this.form.controls[redirectLink].updateValueAndValidity({
            emitEvent: false,
          });
        }
        if (val.offerLinkType === 'Product') {
          this.form.controls[productSku].setValidators([Validators.required]);
          this.form.controls[productSku].updateValueAndValidity({ emitEvent: false });
        } else {
          this.form.controls[productSku].clearValidators();
          this.form.controls[productSku].updateValueAndValidity({
            emitEvent: false,
          });
        }
      }
      else {
        this.form.controls[redirectLink].clearValidators();
        this.form.controls[redirectLink].updateValueAndValidity({ emitEvent: false });
        this.form.controls[productSku].clearValidators();
        this.form.controls[productSku].updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  updateDueIn(): void {
    const dueDateType = this.form.controls.dueDateType;
    this.form.controls.dueIn.value
      ? dueDateType.setValidators(Validators.required)
      : dueDateType.clearValidators();
    dueDateType.updateValueAndValidity();
  }

  record(): void {
    this.offerTemplate = this.form.value;
    if (this.form.value.dueIn && this.form.value.dueDateType) {
      this.offerTemplate.timeDuration = {
        dueIn: this.form.value.dueIn,
        dueDateType: this.form.value.dueDateType,
      };
    }

    if (this.form.value.attachments.length > 0) {
      this.offerTemplate.bannerImageId = this.form.value.attachments[0].id;
      this.offerTemplate.bannerImage = String(
        this.form.value.attachments[0].name
      );
    }

    if (this.form.value.offerType === OfferTypeStringEnum.Notice) {
      this.offerTemplate.bannerImage = '';
      this.offerTemplate.description = '';
      this.offerTemplate.redirectLink = '';
      this.offerTemplate.offerLinkType = 'URL';
      this.offerTemplate.offerCallToAction = '';
      this.offerTemplate.termAndCondition = '';
      this.offerTemplate.offerAmount = 0;
      this.offerTemplate.offerRate = 0;
    }

    if (this.Id) {
      this.state
        .updateOfferTemplate(this.Id, this.offerTemplate)
        .subscribe((data) => {
          this.showToast(data);
        });
    } else {
      this.state.createOfferTemplate(this.offerTemplate).subscribe((data) => {
        this.showToast(data);
      });
    }
  }

  showToast(data?): void {
    if ('id' in data) {
      this.toast.success('Template successfully saved');
      this.closeDialog.emit();
    }
  }

  isTypeOffer(): boolean {
    return this.form.controls.offerType.value === 'Offer';
  }

  isOfferLinkTypeProduct(): boolean {
    return this.form.controls.offerLinkType.value === 'Product';
  }

  convertToHtml(): void {
    this.form
      .get('description')
      .setValue(toHTML(this.form.get('description').value));
    this.form
      .get('termAndCondition')
      .setValue(toHTML(this.form.get('termAndCondition').value));
  }
}
