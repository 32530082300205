import { SelectOption } from '@shared/interfaces';
import { fundSourceEnum, fundSourceStringEnum, SaleTypesEnum, SaleTypesStringEnum } from '@shared/enums';

export const SaleTypesOpt: SelectOption<string>[] = [
  {
    value: SaleTypesEnum[SaleTypesEnum.NewSale],
    label: SaleTypesStringEnum.NewSale,
  },
  {
    value: SaleTypesEnum[SaleTypesEnum.Renewal],
    label: SaleTypesStringEnum.Renewal,
  }
];

export const FundSourceOpt: SelectOption<string>[] = [
  {
    value: fundSourceEnum[fundSourceEnum.Internal],
    label: fundSourceStringEnum.Internal,
  },
  {
    value: fundSourceEnum[fundSourceEnum.External],
    label: fundSourceStringEnum.External,
  }
];
