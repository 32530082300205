import { Injectable } from '@angular/core';
import { ApiClient } from '../api-client.http.service';
import { MemberUrls, ProspectUrls } from '@core/constants';
import { Observable } from 'rxjs';
import { ProspectCreate } from '@shared/models/prospect-create';
import { AddressDto, MemberProfileDto, PaginatedResponseDto } from '../dto';
import { AddressModel, ProvinceModel } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class ProspectApiService {

  constructor(private api: ApiClient) { }

  createProspect(createProspect: ProspectCreate): Observable<ProspectCreate> {
    return this.api.post<ProspectCreate>(
      ProspectUrls.Prospect, createProspect
    );
  }

  deleteProspectProfile(ProspectId: number): Observable<MemberProfileDto> {
    return this.api.delete<MemberProfileDto>(ProspectUrls.deleteProspect(ProspectId));
  }

  getProvinces(): Observable<PaginatedResponseDto<ProvinceModel>> {
    return this.api.get(MemberUrls.provinces);
  }

  createAddress(
    address: AddressModel,
    type: string,
    id: number
  ): Observable<AddressDto> {
    return this.api.post(MemberUrls.createAddress(type, id), address);
  }
}
