<div *ngIf="data.type === 'Conversation'">
  <div fxLayout class="header">
    <div class="title" fxFlex>Add sale</div>
    <div fxFlex="16px" class="close" (click)="dialogRef.close()">
      <i class="icon-cross"></i>
    </div>
  </div>
</div>

<div class="sm-font-container">
  <form [formGroup]="form">
    <mat-form-field [style.width.%]="60"
                    appearance="outline">
      <mat-label>Application I.D.</mat-label>
      <input matInput
             placeholder="Application I.D."
             formControlName="applicationId">
    </mat-form-field>

    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field fxFlex="50%" appearance="outline">
      <mat-label>Campaign</mat-label>
      <mat-select formControlName="campaignId">
        <ng-container *ngFor="let option of campaignOptions">
          <mat-option [value]="option.value">{{option.label}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

     <mat-form-field fxFlex="50%" appearance="outline">
      <mat-label>Branch</mat-label>
      <mat-select formControlName="branchId" [value]="defaultBranch && defaultBranch.value" (valueChange)="defaultBranch && (defaultBranch.value = $event)">
        <ng-container *ngFor="let option of userBranchOptions">
          <mat-option [value]="option.value">{{option.label}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    </div>
    
    <mat-divider></mat-divider>

    <div class="sub-title">
      Products
    </div>
    <div *ngFor="let product of productsList">
      <app-product-card [id]="product"
                        (deleteItem)="delete($event)"
                        [createMode]="true"
                        (savedItem)="saveProduct($event)"></app-product-card>
    </div>
    <div class="product-btns"
         fxLayoutGap="12px"
         fxFill>
      <cm-button color="secondary"
                 (click)="addProduct()">Add Product</cm-button>
      <cm-button color="secondary"
                 #referrersBtn
                 fxFlex="170px">Add Referrer</cm-button>
    </div>
    <div fxLayout="row wrap"
         fxLayoutAlign="start center">
      <app-referrer-card *ngIf="viewInit"
                         [btn]="referrersBtnContainer"
                         (chosenInstances)="addReferrerInstance($event)"></app-referrer-card>
    </div>

  </form>
  <app-link-item [links]="['member', 'account', 'task', 'opportunity']"
                 (chosenInstances)="addInstance($event)"
                 [preselectedConversation]="data.preselectedConversation"
                 [preselectedMembersProspects]="[data.preselectedMember]"
                 [preselectedAccounts]="[data.preselectedAccounts]"></app-link-item>
  <cm-button color="primary"
             [style.width.%]="100"
             class="record-btn"
             (click)="form.valid && record()"
             [disabled]="!products.length || !formLinks.length">
    Record
  </cm-button>
</div>
