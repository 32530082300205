export enum LeadTypeEnum {
  WalkIn = 1,
  Online = 2,
  Phone = 3,
  Other = 4,
}

export const LeadTypeStringEnum = {
  WalkIn: 'Walk In',
  Online: 'Online',
  Phone: 'Phone',
  Other: 'Other',
};
