import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { GlobalStateService } from '@core/services/global.state.service';
import {
  CampaignsModel,
  InstancesModel,
  LinkModel,
  SearchAccountOptionsModel,
  SearchConversationOptionsModel,
  SearchMemberOptionsModel,
} from '@shared/models';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';
import { SalesProductDto } from '@core/services/dto';
import { CustomSnackbarService } from '@core/services/custom-snackbar.service';
import { SalesStateService } from 'app/modules/customer-management/sales/sales.state.service';
import { CmButtonComponent } from '@shared/components/cm-button/cm-button.component';
import { MsalService } from '@azure/msal-angular';
import { UserBranchModel } from '@shared/models/user-Branch.model';
import { SearchMemberProspectOptionsModel } from '@shared/models/search.member.prospect.options.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-create-sales',
  templateUrl: './create-sales.component.html',
  styleUrls: ['./create-sales.component.scss'],
})
export class CreateSalesComponent
  extends autoUnsubscribeMixin()
  implements OnInit, AfterViewInit  {
  form: FormGroup;
  referrers = [];
  products: SalesProductDto[] = [];
  productsList = [];
  formLinks: LinkModel[] = [];
  // preselectedMember: SearchMemberProspectOptionsModel;
  // preselectedAccount: SearchAccountOptionsModel[];
  campaignOptions: CampaignsModel[];
  userBranchOptions: UserBranchModel[];
  GLOBAL_SETTINGS = GLOBAL_SETTINGS;
  viewInit = false;
  defaultBranch: UserBranchModel;
  isSaleCreateOpportunity = true;
  @Input() preselectedMember: SearchMemberProspectOptionsModel;
  @Input() preselectedConversation: SearchConversationOptionsModel;
  @Input() preselectedAccounts: SearchAccountOptionsModel[] = [];
  @ViewChild('referrersBtn', { read: CmButtonComponent })
  referrersBtnContainer: CmButtonComponent;
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() formChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  initialValue: any = {};

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateSalesComponent>,

    private globalState: GlobalStateService,
    private state: SalesStateService,
    private toast: CustomSnackbarService,
    private msalService: MsalService,
    @Inject(MAT_DIALOG_DATA) public data: {
      preselectedMember: SearchMemberProspectOptionsModel[],
      preselectedConversation: SearchConversationOptionsModel,
      preselectedAccounts: SearchAccountOptionsModel[],
      dialogType: string,
      isDetailPage: boolean,
      ref: string,
      type: string,
      isFromOpportunityProfile: boolean
    },
  ) {
    super();
    this.form = this.fb.group({
      applicationId: [null],
      campaignId: [null],
      branchId: [null],
    });
  }

  checkFormChange(): void {
    setTimeout(() => {
      this.initialValue = JSON.stringify(this.form.value);
      this.form.valueChanges.subscribe((value) => {
        if (JSON.stringify(value) !== this.initialValue) {
          this.formChanged.emit(true);
        }
      });
    }, 2000);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInit = true;
    }, 0);
    this.checkFormChange();
  }
  closeDialogWithResult(): void {
    const result = { success: true };
    this.dialogRef.close(result);
  }
  ngOnInit(): void {
    this.globalState.getProductTypes();
    this.globalState.getProducts();
    this.globalState.getCampaigns();
    this.globalState.getBranch();
    this.globalState.userBranches$().subscribe({
      next: (data) => {
        this.userBranchOptions = data;
        this.defaultBranch = this.userBranchOptions.find(branch => branch.isDefault === true);
        this.form.patchValue({
          branchId: this.defaultBranch?.value
        });
      },
      error: (error) => {
        console.error('Error:', error);
      }
    });

    this.globalState.campaigns$().subscribe((data) => {
      this.campaignOptions = data;

      if (this.campaignOptions.find(obj => obj.value === -1)) {
      }
      else {
        this.campaignOptions.push({
          value: -1,
          label: 'N/A'
        });
      }
    });

    this.globalState.userBranches$().subscribe((data) => {
      this.userBranchOptions = data;
    });

    this.globalState.memberProfile.subscribe((data) => {
      if (!this.data?.isFromOpportunityProfile){
        this.data.preselectedMember = [];
      }
      if (data){
        this.data.preselectedMember = [new SearchMemberProspectOptionsModel(data)];
      }
    });

    // this.globalState.preselectedAccount.subscribe((data) => {
    //   if (data) {
    //     this.preselectedAccount = data;
    //   }
    // });
  }
  closeDialog1(): void {
    this.dialogRef.close(true);
  }
  addProduct(): void {
    this.productsList.push(Math.floor(Math.random() * 1000));
  }

  addInstance(links: SearchMemberProspectOptionsModel[]): void {
    const acc = [];
    links.forEach((item) => {
      acc.push(new InstancesModel(item));
    });
    if (this.data.isDetailPage)
    {
      this.formLinks = [{
        ref: this.data.ref,
        type: this.data.type,
      }];
    }
    else
    {
      this.formLinks = acc;
    }
    this.formChanged.emit(true);
  }

  addReferrerInstance(links: SearchMemberOptionsModel[]): void {
    const acc = [];
    links.forEach((item) => {
      if (item.ref) {
        acc.push(item.ref);
      }
    });
    this.referrers = acc;
    this.formChanged.emit(true);
  }

  delete(id): void {
    this.productsList = this.productsList.filter((item) => item !== id);
    this.products = this.products.filter((item) => item.tempId !== id);
  }

  saveProduct(product): void {
    this.products = this.products.filter(
      (item) => item.tempId !== product.tempId
    );
    this.products.push(product);
    this.formChanged.emit(true);
  }

  record(): void {
    const request = {
      campaignId: this.form.controls.campaignId.value !== -1 ? this.form.controls.campaignId.value : null,
      applicationId: this.form.controls.applicationId.value || null,
      branchId: this.form.controls.branchId.value,
      referrers: this.referrers,
      // links: this.formLinks,
      links: this.formLinks.map(link => ({
        ...link,
        type: link.type === 'opportunity' ? 'conversation' : link.type
      })),
      products: this.products,
      isSaleCreateOpportunity: this.isSaleCreateOpportunity,
    };
    this.state.createSale(request).subscribe((res) => {
      if (res) {
        this.formChanged.emit(false);
        this.toast.success('Sale successfully added');
        this.globalState.saleAdded.next(true);
        this.dialogRef.close(true);
        // this.closeDialog.emit();
      }
    },
      error => {
        this.toast.fail(error.message);
      });

  }
}
