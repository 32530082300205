import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { COMMUNICATION_TYPE_OPTIONS } from '@shared/constants/select-options.constants';
import { CommunicationTypeEnum } from '@shared/enums';
import { InteractionCreateInterface } from '@shared/models/interaction-create.interface';

@Component({
  selector: 'app-add-interaction-to-conversation',
  templateUrl: './add-interaction-to-conversation.component.html',
  styleUrls: ['../create-conversation/create-conversation.component.scss']
})
export class AddInteractionToConversationComponent implements OnInit {
  @Input() conversationSubject: string;
  @Output() interactionChange: EventEmitter<InteractionCreateInterface> = new EventEmitter();
  @Output() addingInteraction: EventEmitter<boolean> = new EventEmitter();

  addingInteractionState = false;
  formInteraction: FormGroup;

  communicationTypeOptions = COMMUNICATION_TYPE_OPTIONS;

  constructor(private formBuilder: FormBuilder) {
    this.formInteraction = this.formBuilder.group({
      subject: ['', [Validators.minLength(3), Validators.maxLength(100), Validators.required]],
      communicationType: [CommunicationTypeEnum[CommunicationTypeEnum.Phone], Validators.required],
      description: ['', [Validators.maxLength(2500), Validators.minLength(3), Validators.required]]
    });
  }

  ngOnInit(): void {
    this.formInteraction.valueChanges.subscribe(values => {
      let interaction: InteractionCreateInterface = null;
      if (this.addingInteractionState && this.formInteraction.valid) {
        interaction = {
          subject: values.subject,
          communicationType: values.communicationType,
          description: values.description
        };
      }

      this.interactionChange.emit(interaction);
    });
  }

  clearFormInteraction(): void {
    this.addingInteractionState = !this.addingInteractionState;
    this.addingInteraction.emit(this.addingInteractionState);

    this.formInteraction.patchValue({
      subject: this.conversationSubject,
      communicationType: CommunicationTypeEnum[CommunicationTypeEnum.Phone],
      description: ''
    });
  }
}
