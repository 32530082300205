<app-input-autocomplete [ngModel]="searchAddress"
                        [options]="foundAddresses$ | async"
                        [icon]="'search'"
                        placeholder="Address search"
                        lengthToTriggerSearch="1"
                        (ngModelChange)="searchAddressChanged($event)"
                        (selectedItem)="addressSelected($event)">
  <ng-template #rowTemplate
               let-option>
    <div fxLayoutAlign="space-between center">
      <span>{{option.label}}</span>
      <mat-icon *ngIf="needToRefineAddressSearch(option)"
                class="refine-address-icon">chevron_right</mat-icon>
    </div>
  </ng-template>
</app-input-autocomplete>
