import { Task } from '@shared/models/index';
import { SearchTaskDto } from '@core/services/dto/search-task.dto';

export class SearchTaskModel {
  data: Task[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageNumber: number;
  totalCount: number;
  totalPages: number;

  constructor(dto: SearchTaskDto) {
   this.data = dto.data;
   this.hasNextPage = dto.hasNextPage;
   this.hasPreviousPage = dto.hasPreviousPage;
   this.pageNumber = dto.pageNumber;
   this.totalCount = dto.totalCount;
   this.totalPages = dto.totalPages;
  }
}
