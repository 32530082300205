import { SearchMemberOptionsModel } from '@shared/models/search.member.options.model';
import { SearchMemberProspectOptionsModel } from './search.member.prospect.options.model';

export class InstancesModel {
  ref: string;
  type: string;
  value?: string;
  isProspectInfo?: boolean;

  constructor(dto: SearchMemberProspectOptionsModel) {
    this.ref = dto.ref;
    this.type = dto.type;
    this.isProspectInfo = dto.isProspectInfo;
  }
}
