import { takeUntil } from 'rxjs/operators';
import { autoUnsubscribeMixin } from '@core/helpers/auto-unsubscribe.mixin';
import { Component, ElementRef, HostBinding, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { GlobalStateService } from '@core/services/global.state.service';
import { ModalDialogData } from '@shared/interfaces/ModalDialogData.interface';
import { SearchMemberOptionsModel, SearchAccountOptionsModel } from '@shared/models';
import { OpenedDialogService } from '@core/services/dialog/opened-dialog.service';
import { CollapseDialogType, ConversationType } from '@shared/models/dialog-type.enum';
import { BackDropService } from '@core/services/dialog/backdrop.service';
import { ConfirmationDialogModel } from '../confirmation-dialog/confirmation-dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-header-icon-modal-dialog',
  templateUrl: './header-icon-modal-dialog.component.html',
  styleUrls: ['./header-icon-modal-dialog.component.scss']
})
export class HeaderIconModalDialogComponent extends autoUnsubscribeMixin() implements OnInit {
  preselectedMember: SearchMemberOptionsModel;
  preselectedAccounts: SearchAccountOptionsModel[];

  conversationSubtype: CollapseDialogType = CollapseDialogType.None;
  formStatusChanged = false;
  dailogType: CollapseDialogType = CollapseDialogType.None;
  openDialogId = '';
  @HostBinding('class.minimized') isMinimized = false;
  constructor(
    public dialogRef: MatDialogRef<HeaderIconModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalDialogData,
    private globalState: GlobalStateService,
    private openedDialog: OpenedDialogService,
    private backDrop: BackDropService,
    private dialog: MatDialog,
  ) {
    super();
  }
  ngOnInit(): void {
    this.initSubsriptionsForTask();
    this.dailogType = this.openedDialog.getModalType(this.data.dialogType);
  }

  closeDialog(): void {
    if (this.formStatusChanged) {
      const message = `The information you’ve entered is about to be discarded. Are you sure you want to cancel?`;
      const title = `Alert`;
      const dialogData = new ConfirmationDialogModel(title, message, 'Yes', true, 'No', true, '10px');
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '406px',
      data: dialogData,
      autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.dialogRef.close();
          this.closeMinimizeModal();
        }
      });
    }else {
      this.dialogRef.close();
      this.closeMinimizeModal();
    }
  }

  closeMinimizeModal(): void {
    this.openedDialog.closeDialog(this.openDialogId);
  }

  /*
  * Default dialog type is none;
  * 1) If dialog type changed in case of conversation and this dialog is already in *
  * minimized state then we check same type of modal type is already open except me.
  * if yes, then show alert message.
  * else change the type of dialog to other type: conversation, concern and
  * opportunity.
  * 2) if dialog type changed in case of conversation and this dialog is not in *
  * minimized state then we just change the type of dialog to other type: *
  * conversation, concern and opportunity
  */
  onMinimize(): void {
    if (
      this.conversationSubtype !== CollapseDialogType.None &&
      this.dailogType !== this.conversationSubtype &&
      this.openedDialog.isAlreadyInMinimized(this.openDialogId) ) {
        this.dailogType = this.conversationSubtype;

        if (this.openedDialog.sameModalTypeIsOpenExceptMe(this.openDialogId, this.dailogType)) {
          this.showAlertOfSameTypeModal();
          return;
        }
        this.openedDialog.updateDialogType(this.openDialogId, this.dailogType, 'create');

    }else if (
      this.conversationSubtype !== CollapseDialogType.None &&
      this.dailogType !== this.conversationSubtype) {
        this.dailogType = this.conversationSubtype;
    }

    if (this.openedDialog.isAlreadyInMinimized(this.openDialogId)) {
      this.hideModal();
      return;
    }

    if (this.openedDialog.modalTypeIsOpen(this.dailogType)){
      this.showAlertOfSameTypeModal();
      return;
    }

    this.hideModal();
    this.openedDialog.addDialog({
      id: this.openDialogId,
      title: `Draft ${this.dailogType}`,
      maximizeFn: this.onMiximize.bind(this),
      closeFn: this.closeDialog.bind(this),
      type: this.dailogType,
      icon: this.openedDialog.getDialogIcon(this.dailogType, 'create')
    });
  }

  hideModal(): void {
    this.isMinimized = true;
    this.dialogRef.updatePosition({bottom: '-500%', right: '-500%' });
    this.backDrop.hideBackDrop();
  }

  showAlertOfSameTypeModal(): void {
    const message = `You already have a collapsed item. Please complete that item before collapsing another one.`;
    const dialogData = new ConfirmationDialogModel('Alert', message, 'Ok', false);
    this.dialog.open(ConfirmationDialogComponent, {
      width: '406px',
      data: dialogData,
    });
  }

  private initSubsriptionsForTask(): void {
    if (this.data.dialogType === 'task') {
      this.globalState.memberProfile$()
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(
          data => {
            if (data) {
              this.preselectedMember = new SearchMemberOptionsModel(data);
            }
          }
        );
      this.globalState.preselectedAccount$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(
          data => {
            if (data) {
              this.preselectedAccounts = data;
            }
          }
        );
    }
  }

  get isMinimizeAndMoveAble(): boolean {
    const { dialogType } = this.data;
    return (
      dialogType === 'task' ||
      dialogType === 'create_conversation' ||
      dialogType === 'create_note' ||
      dialogType === 'create_prospect' ||
      dialogType === 'sale'
    );
  }

  onMiximize(): void {
    this.isMinimized = false;
    this.backDrop.showBackdrop();
    this.dialogRef.updatePosition();
    window.dispatchEvent(new Event('resize'));
    // this.openedDialog.closeDialog(this.openDialogId);
  }

  onConversationTypeChange(type: string): void {
    this.conversationSubtype = type as CollapseDialogType;
  }

  onFormChangedStatus($event): void {
    this.formStatusChanged = $event;
  }
}
