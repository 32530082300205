export enum RoleEnum {
  CrmAdmin = 'CRM.Admin',
  CrmUser = 'CRM.User',
  PersonalStaff = 'PersonalStaff',
  PersonalAdmin = 'PersonalAdmin',
  BusinessStaff = 'BusinessStaff',
  BusinessAdmin = 'BusinessAdmin',
  Admin = 'Admin',
  OfferAdmin = 'Offer.Admin',
  OfferUser = 'Offer.User'
}
