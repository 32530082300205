export enum ConversationStatusEnum {
  Opened = 1,
  Pending = 2,
  Closed = 3,
  All = 4,
  Expired = 5,
  Declined = 6
}

export const ConversationStatusStringEnum = {
  Opened: 'Opened',
  Pending: 'Pending',
  Closed: 'Closed',
  Declined: 'Declined',
  Expired: 'Expired',
  Processed: 'Processed',
};

export const ConversationReasonStatusStringEnum = {
  PriceRate: 'Price/Rate',
  NoLongerInterested: 'No Longer Interested',
  DoesNotQualify: 'Does not qualify',
  NotRelevant: 'Not relevant',
  NotReadyToProceed: 'Not ready to proceed',
  BetterProductAtOFI: 'Better product at OFI',
  UnableToReach: 'Unable to Reach'
};
