export class AddressModel {
  id: number | string;
  provinceId: number;
  name?: string;
  type?: string;
  poBox: boolean;
  street: string;
  streetNumber: string;
  apartment: string;
  city: string;
  postalCode: string;
  dateOfDeath?: string;
  isPrimary: boolean;
}
