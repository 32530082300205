export enum SaleTypesEnum {
  NewSale = 1,
  Renewal = 2
}

export enum fundSourceEnum {
  Internal = 1,
  External = 2
}

export const SaleTypesStringEnum = {
  NewSale: 'New Sale',
  Renewal: 'Renewal'
};

export const fundSourceStringEnum = {
  Internal: 'Internal',
  External: 'External'
};
