import { fromEvent, Subscription } from 'rxjs';
import { Component, Input, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { CmButtonPalette } from '@shared/components/cm-button/cm-button.constants';
import { IconName } from '@shared/constants/icon-name';

@Component({
  selector: 'cm-button',
  templateUrl: './cm-button.component.html',
  styleUrls: ['./cm-button.component.scss']
})
export class CmButtonComponent implements OnInit, OnDestroy {

  @Input() color: CmButtonPalette = 'primary';
  @Input() icon: IconName | null;
  @Input() disabled: boolean;
  @Input() active = '';
  @Input() textPosition = 'center';
  @Input() size: 'default' | 'large-button' = 'default';
  @Input() type: 'submit' | 'button' = 'submit';
  subscription: Subscription;

  constructor(private elRef: ElementRef) {
  }

  ngOnInit(): void {
    const el = this.elRef.nativeElement;
    this.subscription = fromEvent(el, 'click', { capture: true })
      .subscribe((e: any) => {
        if (this.disabled) {
          e.stopPropagation();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
