import { UserBranchDto } from '@core/services/dto/user-Branch.dto';

export class UserBranchModel {
  label: string;
  value: number;
  isDefault: boolean;

  constructor(dto: UserBranchDto) {
    this.value = dto.id;
    this.label = dto.name;
    this.isDefault = dto.isDefault;
  }
}
