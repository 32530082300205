import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClient } from '@core/services/api-client.http.service';
import { AsappServiceUrls, CommonUrls } from '@core/constants';
import { SiteDetailDto, SiteDto } from '@core/services/dto';

@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {

  constructor(private api: ApiClient) {
  }

  getDefaultLocation(): Observable<SiteDto> {
    return this.api.get<SiteDto>(CommonUrls.site);
  }
  getSiteDetails(): Observable<SiteDetailDto> {
    return this.api.get<SiteDetailDto>(AsappServiceUrls.siteDetails);
  }
}
