import { AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class AssigneeFormControl extends FormControl {
  constructor(
    formState: any = null,
    validator?: ValidatorFn | ValidatorFn[] | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
    super(formState, validator, asyncValidator);

    if (Array.isArray(validator)) {
      validator.push(this.requireObjectValidator());
    }
    else {
      validator = !!validator ? [validator, this.requireObjectValidator()] : this.requireObjectValidator();
    }

    super.setValidators(validator);
  }

  private requireObjectValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      return !control.value || typeof control.value === 'object'
        ? null
        : { userIsNotSelected: true };
    };
  }
}
