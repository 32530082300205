<div class="sm-font-container">
  <form [formGroup]="form">
    <mat-form-field appearance="outline"
                    [style.width.%]="100">
      <textarea matInput
                placeholder="Note Description Text Here"
                formControlName="description"
                required></textarea>
    </mat-form-field>

    <mat-checkbox formControlName="isSticky" 
      [disableRipple]="true"
      (change)="updateSticky()"
      name="sticky">Sticky (High Importance)</mat-checkbox>

    <mat-form-field class="sticky-date" *ngIf="form.controls['isSticky'].value"
                    appearance="outline">
      <mat-label>Sticky Duration</mat-label>
      <input matInput
             formControlName="stickyDueDate"
             [matDatepicker]="picker"
             [min]="todayDate">
      <mat-datepicker-toggle matSuffix
                             [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </form>
  <app-link-item [links]="['prospect', 'account']"
                 (chosenInstances)="addInstance($event)"
                 [preselectedMembersProspects]="[preselectedMember]"
                 [preselectedAccounts]="preselectedAccount"></app-link-item>

  <mat-divider></mat-divider>
  <app-upload-file [fileType]="FileTypeEnum.Crm"
                   [parentForm]="form"
                   [readOnly]="false"></app-upload-file>

  <cm-button color="primary"
             class="record-btn"
             (click)="form.valid && formLinks.length && !isNoteDisabled && record()"
             [disabled]="!form.valid || isNoteDisabled">
    Record
  </cm-button>
</div>
