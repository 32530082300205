export class UserNameModel {
  firstName?: string;
  lastName?: string;
  id?: string;

  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.id = '';
  }
}
