               <ng-container *ngIf="conversationType === 'opportunity'; else defaultLinks">
                <app-link-item 
                  [links]="['prospect', 'account', 'task', 'sale']"
                  [preselectedMembersProspects]="!!preselectedMember ? [preselectedMember] : []"
                  [preselectedAccounts]="preselectedAccount"
                  (chosenInstances)="addInstance($event)">
                </app-link-item>
              </ng-container>
              
              <ng-template #defaultLinks>
                <app-link-item 
                  [links]="['prospect', 'account', 'task']"
                  [preselectedMembersProspects]="!!preselectedMember ? [preselectedMember] : []"
                  [preselectedAccounts]="preselectedAccount"
                  (chosenInstances)="addInstance($event)">
                </app-link-item>
              </ng-template>