export enum UserStatusTypeEnum {
  Deleted = 0,
  New = 1,
  Pending = 2,
  Disabled = 3,
  Active = 4
}

export const UserStatusTypeStringEnum = {
  Deleted: 'Deleted',
  New: 'New',
  Pending: 'Pending',
  Disabled: 'Disabled',
  Active: 'Active'
};
