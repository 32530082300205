import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccessService } from '@core/services/access.service';
import { RoleEnum } from '@shared/enums/role.enum';

@Directive({
  selector: '[appCheckRole]'
})
export class CheckRoleDirective implements OnInit {
  @Input() appCheckRole: RoleEnum | RoleEnum[];

  constructor(private accessService: AccessService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }

  ngOnInit(): void {
    const roles = Array.isArray(this.appCheckRole) ? this.appCheckRole : [this.appCheckRole];

    if (this.accessService.checkUserRoles(roles)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
