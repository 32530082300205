import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalDialogData } from '@shared/interfaces/ModalDialogData.interface';
import { HeaderIconModalDialogComponent } from '@shared/components/header-icon-modal-dialog/header-icon-modal-dialog.component';
import { HeaderIconModalDialogModule } from '@shared/components/header-icon-modal-dialog/header-icon-modal-dialog.module';
import { PermissionStringEnum } from '@shared/enums';
import { BackDropService } from '@core/services/dialog/backdrop.service';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { OpenDialogInterface } from '@shared/models/open-dialog.interface';
import { CollapseDialogType } from '@shared/models/dialog-type.enum';
import { OpenedDialogService } from '@core/services/dialog/opened-dialog.service';

@Component({
  selector: 'app-header-action-item',
  templateUrl: './header-action-item.component.html',
  styleUrls: ['./header-action-item.component.scss']
})
export class HeaderActionItemComponent implements OnInit {

  @Input() actionType = '';
  @Input() actionText = '';
  @Input() hasPermission = Boolean;

  constructor(
    public dialog: MatDialog,
    public backDrop: BackDropService,
    public openedDialog: OpenedDialogService,
  ) { }

  dialogData: ModalDialogData;
  conversationType: CollapseDialogType = CollapseDialogType.Conversation;
  openDialogId = '';
  openDialog(): void {
    this.backDrop.showBackdrop();
    const dialogRef = this.dialog.open(HeaderIconModalDialogComponent, {
      width: '550px',
      data: this.dialogData,
      position: {top: '12%'},
      disableClose: true,
      hasBackdrop: false,
      scrollStrategy: new NoopScrollStrategy(),
      closeOnNavigation: false,
      autoFocus: false,
    });
    dialogRef.componentInstance.openDialogId = dialogRef.id;
    dialogRef.afterClosed().subscribe(() => {
      this.backDrop.hideBackDrop();
    });
  }

  ngOnInit(): void {
    this.dialogData = {dialogTitle:  this.actionText, buttonTitle: 'Record', dialogType: this.actionType};
  }
}
