export enum ConversationTypeEnum {
  Conversation = 1,
  Opportunity = 2,
  Concern = 3,
  All = 4
}

export const ConversationTypeStringEnum = {
  Conversation: 'Conversation',
  Opportunity: 'Opportunity',
  Concern: 'Concern',
  All: 'All'
};
