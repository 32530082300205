import { SalesDto } from '@core/services/dto';
import { LinkDto } from '@core/services/dto/link.dto';
import { formatDateSimple } from '@shared/utils';
import { ProductModel } from '@shared/models/product.model';
import { SoldBy } from '@shared/interfaces';

export class SalesModel {
  id: number;
  saleId: number;
  applicationId: string;
  renewalDate: Date;
  fundSource: string;
  saleType: string;
  soldBy: SoldBy;
  soldByUser: string;
  product: ProductModel;
  productName: string;
  productType: number;
  productTypeName: string;
  soldDate: string;
  value: number;
  links: LinkDto[];
  member: LinkDto[];
  campaign: {
    id: number;
    name: string;
  };
  branchId: number;
  actualValue?: number;
  maturityDate?: Date;

  constructor(dto: SalesDto) {
    this.id = dto.id;
    this.saleId = dto.saleId;
    this.applicationId = dto.applicationId;
    this.renewalDate = dto.renewalDate;
    this.fundSource = dto.fundSource;
    this.saleType = dto.saleType;
    this.soldBy = dto.soldBy;
    this.soldByUser = `${dto.soldBy?.firstName} ${dto.soldBy?.lastName}`;
    this.product = new ProductModel(dto.product);
    this.productName = dto.product?.name;
    this.soldDate = dto.soldDate
      ? formatDateSimple(dto.soldDate)
      : dto.soldDate;
    this.value = dto.value;
    this.links = dto.links;
    this.member = dto.links;
    this.productType = dto.product?.type?.id;
    this.productTypeName = dto.product?.type?.name;
    this.campaign = dto.campaign;
    this.branchId = dto.branchId;
    this.actualValue = dto.actualValue;
    this.maturityDate = dto.maturityDate;
  }

  getDisplayValue(): string {
    return !!this.campaign ? `${this.campaign.id} - ${this.campaign.name}` : '';
  }
}
