import { PeopleDto } from '@core/services/dto';
export class PeopleModel {
  id: number;
  memberId: number;
  firstName: string;
  lastName: string;
  role?: string;
  status: string;
  name: string;

  constructor(dto: PeopleDto) {
    this.id = dto.id;
    this.memberId = dto.memberId;
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.role = dto.role;
    this.status = dto.status;
    this.name = this.firstName + ' ' + this.lastName;
  }
}
