<div (click)="open(instance)"
     [class.disabled]="instance?.disabled"
     class="link"
     fxLayout="row"
     fxLayoutAlign="start center">
  <div *ngIf="!showLabel"
       class="type-icon {{instance?.type}}">
    <i class="icon-{{instance?.type}}"></i>
  </div>
  <div class="instance-name">
    {{showLabel ? instance?.label : instance?.value}}
  </div>
  <div (click)="removeInstance()"
       *ngIf="!readOnly"
       class="remove-instance">
    <i class="icon-minus_circle"></i>
  </div>
</div>
