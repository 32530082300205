export enum CollapseDialogType {
  Conversation = 'Conversation',
  Opportunity = 'Opportunity',
  Concern = 'Concern',
  Task = 'Task',
  Sale = 'Sale',
  Prospects = 'Prospect',
  Notes = 'Note',
  ConversationView = 'ConversationView',
  OpportunityView = 'OpportunityView',
  ConcernView = 'ConcernView',
  TaskView = 'TaskView',
  SaleView = 'SaleView',
  ProspectView = 'ProspectView',
  NotesView = 'NotesView',
  None = 'None',
}

export enum ConversationType {
  Conversation = 'Conversation',
  Opportunity = 'Opportunity',
  Concern = 'Concern'
}

export enum DialogParentType {
  Conversation = 'Conversation',
  Opportunity = 'Opportunity',
  Concern = 'Concern',
  Task = 'Task',
  Sale = 'Sale',
  Prospects = 'Prospect',
  Notes = 'Note',
}
