import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinStrings'
})
export class JoinStringsPipe implements PipeTransform {

  transform(value: string[], ...args: unknown[]): string {
    if (!value || value.length === 0) {
      return '';
    }

    if (value.length === 1) {
      return value[0];
    }
    const lastString = value.pop();
    return `${value.join(', ')} and ${lastString}`;
  }
}
