import { AbstractControlOptions, AsyncValidatorFn, FormControl, ValidatorFn } from '@angular/forms';
import { GLOBAL_SETTINGS } from '@shared/constants/global-settings';

export class MinLengthToTriggerFormControl extends FormControl {
  inputMinLength: number;

  GLOBAL_SETTINGS = GLOBAL_SETTINGS;

  constructor(
    formState: any = null,
    inputMinLength: number = GLOBAL_SETTINGS.minLengthToTriggerSearch,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
    super(formState, validatorOrOpts, asyncValidator);

    this.inputMinLength = inputMinLength;
  }

  updateValueAndValidity(opts: { onlySelf?: boolean, emitEvent?: boolean } = {}): void {
    opts.emitEvent = (!this.value || (this.value as string).length >= this.inputMinLength) ? opts?.emitEvent : false;

    super.updateValueAndValidity(opts);
  }
}
