import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConversationModel } from '@shared/models/conversation.model';
import { ConversationUrls, WorkflowUrls } from '@core/constants/endpoints.constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { ConversationApiRequest } from '@shared/models/requests/conversation.request.dto';
import { ConversationDto, ImportConversationDto, PaginatedResponseDto } from '@core/services/dto';
import { SearchConversationDto } from '@core/services/dto/search-conversation.dto';
import { ConversationUpdateApiRequest } from '@shared/models/requests/conversation-update.request.dto';
import { ConcernCreateModel, ConversationCreateModel, ConversationWorkflowModel, OpportunityCreateModel, WorkflowModel } from '@shared/models';
import { ConversationSummaryDto } from '@core/services/dto';
import { ImportOpportunityApiRequest } from '@shared/models/requests/Import-opportunity.request.dto';
import { ConcernTypeDto } from '../dto/concern-type.dto';

@Injectable({
  providedIn: 'root'
})
export class ConversationApiService {

  private conversationUpdateSubject = new BehaviorSubject<void>(null);
  constructor(private api: ApiClient) {
  }

  notifyConversationUpdate(): void {
    this.conversationUpdateSubject.next();
  }

  getConversationUpdateNotifier(): Observable<void> {
    return this.conversationUpdateSubject.asObservable();
  }

  setConversation(conversation: ConversationCreateModel): Observable<ConversationModel> {
    return this.api.post<ConversationModel>(ConversationUrls.conversations, conversation);
  }

  setConcern(concern: ConcernCreateModel): Observable<ConversationModel> {
    return this.api.post<ConversationModel>(ConversationUrls.concerns, concern);
  }

  setOpportunity(concern: OpportunityCreateModel): Observable<ConversationModel> {
    return this.api.post<ConversationModel>(ConversationUrls.opportunities, concern);
  }

  updateConversation(conversationId: number, request: ConversationCreateModel): Observable<ConversationModel> {
    return this.api.put(ConversationUrls.conversation(conversationId), request);
  }

  updateConcern(conversationId: number, request: ConcernCreateModel): Observable<ConversationModel> {
    return this.api.put(ConversationUrls.concern(conversationId), request);
  }

  updateOpportunity(conversationId: number, request: OpportunityCreateModel): Observable<ConversationModel> {
    return this.api.put(ConversationUrls.opportunity(conversationId), request);
  }

  getConversations(request: ConversationApiRequest): Observable<PaginatedResponseDto<ConversationDto>> {
    return this.api.get<PaginatedResponseDto<ConversationDto>>(ConversationUrls.conversations, request);
  }

  GetImportedConversationEmails(request: ConversationApiRequest): Observable<any> {
    return this.api.get(ConversationUrls.importedConversationEmails, request);
  }

  getSearchConversations(request: ConversationApiRequest): Observable<SearchConversationDto> {
    return this.api.get<SearchConversationDto>(ConversationUrls.conversations, request);
  }

  getConversation(id: number): Observable<ConversationDto> {
    return this.api.get<ConversationDto>(ConversationUrls.conversation(id));
  }

  getConcernTypes(): Observable<PaginatedResponseDto<ConcernTypeDto>> {
    return this.api.get<PaginatedResponseDto<ConcernTypeDto>>(ConversationUrls.concerntypes);
  }
  getConcernType(id: number): Observable<PaginatedResponseDto<ConcernTypeDto>> {
    return this.api.get<PaginatedResponseDto<ConcernTypeDto>>(ConversationUrls.concerntype(id));
  }


  createConversationWorkflow(conversationWorkflow: ConversationWorkflowModel, conversationId: number): Observable<WorkflowModel> {
    return this.api.post<WorkflowModel>(ConversationUrls.conversationWorkflows(conversationId), conversationWorkflow);
  }

  getConversationWorkflows(conversationId: number): Observable<PaginatedResponseDto<WorkflowModel>> {
    return this.api.get<PaginatedResponseDto<WorkflowModel>>(ConversationUrls.conversationWorkflows(conversationId));
  }

  getConversationSummary(statuses: string[], type): Observable<ConversationSummaryDto> {
    return this.api.get<ConversationSummaryDto>(ConversationUrls.summary, {conversationStatuses: statuses, conversationType: type});
  }

  getImportedFiles(request: ImportOpportunityApiRequest): Observable<PaginatedResponseDto<ImportConversationDto>> {
    return this.api.get<PaginatedResponseDto<ImportConversationDto>>(ConversationUrls.conversationImports, request);
  }

  importOpportunity(id: number): Observable<any> {
    return this.api.put(ConversationUrls.conversationImport(id));
  }

  deleteImportOpportunity(id: number): Observable<any> {
    return this.api.delete(ConversationUrls.conversationImport(id));
  }

  deleteConversation(id: number): Observable<any> {
    return this.api.delete(ConversationUrls.conversation(id));
  }

  downloadOpportunityImportTemplate(id: number): Observable<any> {
    return this.api.getFile(ConversationUrls.conversationImportTemplate(id), null);
  }
  UpdateConversionAssineeInBatch(finaldata: any): Observable<any>{
    return this.api.put(ConversationUrls.conversationAssineetBatch, finaldata, { observe: 'response' });
  }
  UpdateConcernAssineeInBatch(finaldata: any): Observable<any>{
    return this.api.put(ConversationUrls.concernsAssineetBatch, finaldata);
  }
  UpdateopportunitiesAssineeInBatch(finaldata: any): Observable<any>{
    return this.api.put(ConversationUrls.opportunitiesAssineetBatch, finaldata);
  }
  UpdateTaskAssineeInBatch(finaldata: any): Observable<any>{
    return this.api.put(ConversationUrls.tasksAssineetBatch, finaldata);
  }
  UpdateWorkFlowTaskAssineeInBatch(finaldata: any): Observable<any>{
    return this.api.put(WorkflowUrls.workFlowTasksAssineetBatch, finaldata);
  }
}
