export interface AccountModel {
  accountNumber: string;
  mortgageAmount?: number;
  amountRepaired?: number | string;
  outstandingBalance: number;
  paymentFrequency: string;
  paymentAmount: number;
  remainingAmortization?: string;
  dueDate: string;
  currentArrears: string;
  overdueDays: number | string;
  status: string;
  subType: string;
  type: string;
  branch: string;
  openedDate: string;
  ownershipType: string;
  purpose: string;
  maturityDate: string;
  revolving: string;
  riskRating: string;
  termLength: string;
  termRemaining: string;
  termStartDate: string;
  name: string;

  accountBalance?: number;
  accountCharges: number | string;
  annualFee: number | string;
  authorizedOverdraft: number | string;
  availableBalance?: number | string;
  interestRate: number | string;
}
