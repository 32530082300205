import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class CustomSnackbarService {
  constructor(
    private snackBar: MatSnackBar,
    private zone: NgZone
  ) {
  }

  open(message: string, action = 'OK', duration = 10000, className?): void {
    this.zone.run(() => {
      this.snackBar.open(message, action, { duration, verticalPosition: 'top', horizontalPosition: 'right', panelClass: className });
    });
  }

  success(message: string, duration?: number): void {
    this.open(message, 'OK', duration, `mat-toolbar__success`);
  }

  fail(message: string, duration?: number): void {
    this.open(message, 'OK', duration, `mat-toolbar__error`);
  }

  warning(message: string, duration?: number): void {
    this.open(message, 'OK', duration, `mat-toolbar__warning`);
  }
}
