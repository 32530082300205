import { SearchAccountDto } from '@core/services/dto';
import { AccountDetailsModel } from '@shared/models/account-details.model';

export class SearchAccountModel {
  data: AccountDetailsModel[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageNumber: number;
  totalCount: number;
  totalPages: number;

  constructor(dto: SearchAccountDto) {
    this.data = dto.data;
    this.hasNextPage = dto.hasNextPage;
    this.hasPreviousPage = dto.hasPreviousPage;
    this.pageNumber = dto.pageNumber;
    this.totalCount = dto.totalCount;
    this.totalPages = dto.totalPages;
  }
}
