import { LinkModel } from '@shared/models/index';
import { UserDto } from '@core/services/dto';

export class SearchUserOptionsModel {
  label?: string;
  ref?: string;
  isGroup?: boolean;
  value?: string;
  status?: string;
  links?: LinkModel[];
  type?: string;
  firstName?: string;
  lastName?: string;
  name?: string;

  constructor(dto: UserDto) {
    this.label = `${dto.firstName ?? dto.name} ${dto.lastName ?? ''}`;
    this.firstName = dto.firstName;
    this.lastName = dto.lastName;
    this.name = dto.name;
    this.value = this.label;
    this.ref = dto.id ?? dto.userId;
    this.isGroup = dto.isGroup;
    this.status = dto.status;
    this.type = dto.type;
  }
}
