import { AttachmentModel } from '@shared/models/attachment.model';
import { WorkflowTaskModel } from '@shared/models/index';
import { WorkflowTemplateDto } from '@core/services/dto';


export class WorkflowTemplateModel {
  id?: number;
  name: string;
  description: string;
  createdDate?: string;
  type: string;
  status: string;
  completionDue: {
    dueIn: number,
    dueDateType: string
  };
  attachments?: AttachmentModel[];
  tasks?: WorkflowTaskModel[];
  ref?: number;

  constructor(dto: WorkflowTemplateDto) {
    this.id = dto.id;
    this.name = dto.name;
    this.description = dto.description;
    this.status = dto.status;
    this.type = dto.type;
    this.ref = this.id;
  }
}
