import { SelectOption } from '@shared/interfaces';
import { TaskPriorityEnum, TaskPriorityStringEnum, TaskTypeEnum, TaskTypeStringEnum } from '@shared/enums';

export const NoneTaskTemplateDropdownOption: SelectOption<number> = {
  value: null,
  label: 'No task template'
};

export const EmptyTaskTemplateDropdownOption: SelectOption<number> = {
  value: 0,
  label: 'No active templates yet',
  disabled: true
};

export const TypeOptions: SelectOption<string>[] = [
  {
    value: TaskTypeEnum[TaskTypeEnum.General],
    label: TaskTypeStringEnum.General
  },
  {
    value: TaskTypeEnum[TaskTypeEnum.Email],
    label: TaskTypeStringEnum.Email
  },
  {
    value: TaskTypeEnum[TaskTypeEnum.Phone],
    label: TaskTypeStringEnum.Phone
  },
  {
    value: TaskTypeEnum[TaskTypeEnum.FollowUp],
    label: TaskTypeStringEnum.FollowUp
  },
  {
    value: TaskTypeEnum[TaskTypeEnum.SMS],
    label: TaskTypeStringEnum.SMS
  }
];

export const PriorityOptions: SelectOption<string>[] = [
  {
    value: TaskPriorityEnum[TaskPriorityEnum.Low],
    label: TaskPriorityStringEnum.Low
  },
  {
    value: TaskPriorityEnum[TaskPriorityEnum.Medium],
    label: TaskPriorityStringEnum.Medium
  },
  {
    value: TaskPriorityEnum[TaskPriorityEnum.High],
    label: TaskPriorityStringEnum.High
  }
];
