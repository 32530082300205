import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkflowUrls } from '@core/constants/endpoints.constants';
import { ApiClient } from '@core/services/api-client.http.service';
import { PaginatedResponseDto, WorkflowTemplateDto } from '@core/services/dto';
import { WorkflowTemplateRequest } from '@shared/models/requests/workflow-template.request.dto';
import { WorkflowTemplateCreateRequest } from '@shared/models/requests/workflow-template-create.request.dto';
import { WorkflowTemplateModel, WorkflowModel } from '@shared/models';
import { WorkflowTemplateUpdateRequest } from '@shared/models/requests/workflow-template-update.request.dto';

@Injectable({
  providedIn: 'root'
})
export class WorkflowApiService {

  constructor(private api: ApiClient) {
  }

  createWorkflowTemplate(request: WorkflowTemplateCreateRequest): Observable<WorkflowTemplateDto> {
    return this.api.post(WorkflowUrls.workflowTemplates, request);
  }

  getWorkflowTemplate(request: WorkflowTemplateRequest): Observable<PaginatedResponseDto<WorkflowTemplateDto>> {
    return this.api.get<PaginatedResponseDto<WorkflowTemplateDto>>(WorkflowUrls.workflowTemplates, request);
  }

  getWorkflowTemplateById(id: number): Observable<WorkflowTemplateModel> {
    return this.api.get<WorkflowTemplateDto>(WorkflowUrls.workflowTemplate(id));
  }

  updateWorkflowTemplate(id: number, workflow: WorkflowTemplateUpdateRequest): Observable<WorkflowTemplateModel> {
    return this.api.put(WorkflowUrls.workflowTemplate(id), workflow);
  }

  getWorkflow(id: number): Observable<WorkflowModel> {
    return this.api.get<WorkflowModel>(WorkflowUrls.workflow(id));
  }
}
