import { ProvinceModel } from '@shared/models';
import { CanadaPostAddressRetrieveDto } from '@core/services/dto/canada-post-address-retrieve.dto';

export class CanadaPostAddressModel {
  id: string;
  apartment: string;
  street: string;
  streetNumber: string;
  city: string;
  provinceId: number | string;
  postalCode: string;
  poBox: boolean;
  country: string;
  addressLine1: string;
  provinceName: string;

  constructor(addr: CanadaPostAddressRetrieveDto, provinces: ProvinceModel[]) {
    // const tempResult = provinces && provinces.length && addr.provinceCode ? provinces.find(x => x.key === addr.provinceCode) : null;
    // this.provinceId = addr.provinceCode;
    this.poBox = !!addr.poBoxNumber;
    this.id = addr.id;
    this.apartment = addr.apartment;
    this.street =  addr.street;
    this.streetNumber = this.poBox ? addr.poBoxNumber : addr.streetNumber;
    this.city = addr.city;
    this.provinceName = addr.provinceCode;
    this.provinceId = provinces === undefined ? provinces.find(x => x.key === addr.provinceCode).id : addr.provinceCode;
    this.postalCode = addr.postalCode === undefined ? addr.postalCode : addr.postalCode.replace(' ', '');
    this.addressLine1 = addr.addressLine1;
    this.country = addr.country;
  }
}
