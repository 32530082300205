import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogModel } from './confirmation-dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  title: string;
  message: string;
  submitButton: string;
  showCancelBtn = true;
  cancelButtonText: string;
  borderCancelBtn: boolean;
  buttonsGap: string;
  isHtmlContent = false;
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogModel) {
    this.title = data.title;
    this.message = data.message;
    this.submitButton = data.submitButton;
    this.showCancelBtn = data.showCancelBtn;
    this.cancelButtonText = data.cancelButtonText;
    this.borderCancelBtn = data.borderCancelBtn;
    this.buttonsGap = data.buttonsGap;
    this.isHtmlContent = data.isHtmlContent;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
